import { useEffect, useState } from 'react'
import {
  selectHPOneSubscriptionsParentSku,
  selectIsEmpty
} from '@/context/reducer'
import {
  getSkuProductMap,
  sortAllSubscriptionsBasedOnRules
} from '@/helpers/uiConvert'
import useAppContext from '@/hooks/useAppContext'
import useDeviceCachesInfo from '@/hooks/useDeviceCachesInfo'
import useGetProductByParentSku from '@/hooks/useGetProductsByParentSku/useGetProductsByParentSku'
import useHPOneSubscriptionsInfo from '@/hooks/useHPOneSubscriptionsInfo'
import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import { DeviceCache, SubscriptionType } from '@/types/Subscription'
import {
  DeviceGrantInfo,
  InstantInkSubscription,
  InstantInkSubscriptionInfo
} from '@/types/subscription-response'
import { ProductInterface } from 'src/__generated__/graphql'
import useDeviceGrantInfo from '../useDeviceGrantInfo'

type SubscriptionsResponse = {
  emptyFlag: boolean
  isFetching: boolean
  hpOneError: any
  deviceError: unknown
  instantInkError: any
  devicesGrantError: unknown
  deviceCaches: DeviceCache[]
  allSubscriptions: Array<SubscriptionType | InstantInkSubscription>
  instantInkSubscription: Array<InstantInkSubscriptionInfo>
  skuProductMap: Map<string, ProductInterface>
  devicesGrant: DeviceGrantInfo
  forceRefresh?: () => void
}

const useSubscriptions = (): SubscriptionsResponse => {
  const { forceRefresh: hpOneForceRefresh } = useHPOneSubscriptionsInfo()
  const { forceRefresh: iIForceRefresh } = useInstantInkSubscriptionInfo()
  const { forceRefresh: deviceCachesForceRefresh } = useDeviceCachesInfo()
  const { forceRefresh: deviceGrantInfoForceRefresh } = useDeviceGrantInfo()
  const { state } = useAppContext()
  const emptyFlag = selectIsEmpty(state)
  // HP One
  const {
    isFetching: isFetchingHpOne,
    data: hpOneSubscriptions,
    error: hpOneError
  } = state.hpOneSubscriptionsInfo

  // Instant Ink
  const {
    isFetching: isFetchingInstantInk,
    data: instantInkSubscription,
    error: instantInkError
  } = state.instantInkSubscriptionInfo

  // Device Cache
  const {
    isFetching: isFetchingDeviceCache,
    data: deviceCaches,
    error: deviceError
  } = state.deviceCachesInfo

  // Devices Grant
  const {
    isFetching: isFetchingDevicesGrant,
    data: devicesGrant,
    error: devicesGrantError
  } = state.deviceGrantInfo

  // Product Data
  const parentSkus = selectHPOneSubscriptionsParentSku(state)
  const { data: productData } = useGetProductByParentSku(parentSkus)
  const [skuProductMap, setSkuProductMap] = useState(null)

  const [allSubscriptions, setAllSubscriptions] = useState<
    Array<SubscriptionType | InstantInkSubscription>
  >([])

  useEffect(() => {
    setSkuProductMap(getSkuProductMap(productData))
  }, [productData])

  useEffect(() => {
    if (!isFetchingHpOne && !isFetchingInstantInk) {
      const subscriptionArray = instantInkSubscription?.map(
        (obj) => obj.subscription
      )
      setAllSubscriptions(
        sortAllSubscriptionsBasedOnRules([
          ...(hpOneSubscriptions || []),
          ...(subscriptionArray || [])
        ])
      )
    }
  }, [
    isFetchingHpOne,
    isFetchingInstantInk,
    hpOneSubscriptions,
    instantInkSubscription
  ])

  const forceRefresh = (): void => {
    // Resetting state variables
    setAllSubscriptions([])
    setSkuProductMap(null)

    //refreshing data from hooks
    hpOneForceRefresh()
    iIForceRefresh()
    deviceCachesForceRefresh()
    deviceGrantInfoForceRefresh()
  }

  return {
    emptyFlag,
    isFetching:
      isFetchingDeviceCache ||
      isFetchingHpOne ||
      isFetchingInstantInk ||
      isFetchingDevicesGrant,
    hpOneError,
    deviceError,
    instantInkError,
    devicesGrantError,
    deviceCaches,
    allSubscriptions,
    instantInkSubscription,
    skuProductMap,
    devicesGrant,
    forceRefresh
  }
}

export default useSubscriptions
