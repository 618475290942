import styled from 'styled-components'
import tokens from '@veneer/tokens'
const { size1 } = tokens.layout
export const SubscriptionDesc = styled.div`
  diplay: flex;
  flex-flow: column;
  width: 100%;
  > span {
    display: block;
  }
  .planID {
    margin-bottom: ${size1};
  }
`

export const ManagementLinkStyle = styled.div`
  padding-top: 5px;
`
