import { useEffect, useMemo, useState } from 'react'
import useShellContext from '@/hooks/useShellContext'

import {
  DeviceCache,
  Device,
  AddOn,
  SubscriptionStateEnum,
  SubscriptionEntity
} from '@/types/Subscription'

import { mergeProductNDeviceCacheEntity } from '@/helpers/uiConvert'
import { ProductInterface } from 'src/__generated__/graphql'
import { SubscriptionStateResponse } from 'src/types/subscription-state'
import { GrantControllerSvcClient } from '@jarvis/web-stratus-client'

export const useFetchSubscriptionDetails = ({
  subObj,
  skuProductMap,
  deviceCaches
}: {
  subObj: SubscriptionStateResponse
  skuProductMap?: Map<string, ProductInterface>
  deviceCaches?: DeviceCache[]
}) => {
  const [device, setDevice] = useState<Device>(null)
  const [addOns, setAddOns] = useState<AddOn[]>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { stack, authProvider } = useShellContext()
  const grantClient = useMemo(
    () => new GrantControllerSvcClient(stack, authProvider),
    [stack, authProvider]
  )

  useEffect(() => {
    const result = mergeProductNDeviceCacheEntity({
      subObj,
      skuProductMap,
      deviceCaches
    })

    setAddOns(result.addOns)

    const VALID_PRINTER: SubscriptionStateEnum[] = [
      SubscriptionStateEnum.ACTIVE,
      SubscriptionStateEnum.PENDING
    ]
    const getPrinter = async (): Promise<SubscriptionEntity> => {
      if (result.mainDevices?.length === 1) {
        setDevice(result.mainDevices[0])
        setIsLoading(false)
        return
      } else if (result.mainDevices?.length === 0) {
        setDevice(null)
        setIsLoading(false)
        return
      }

      const validPrinters = result.mainDevices?.filter((entity) =>
        VALID_PRINTER.includes(entity.state)
      )

      if (validPrinters?.length === 1) {
        setDevice(validPrinters[0])
        setIsLoading(false)
        return
      }

      let tryIndex = 0
      let returnIndex = -1

      while (tryIndex < result.mainDevices?.length && returnIndex === -1) {
        try {
          const deviceId: string = result?.mainDevices[tryIndex]?.uuid
          if (deviceId) {
            const grantDetails = await grantClient.getGrants({
              level: 'DEVICE',
              deviceId,
              grant: 'ws-hp.com/paas'
            })

            if (grantDetails?.data?.totalSize > 0) {
              returnIndex = tryIndex
              break
            }
          }
          tryIndex++
        } catch (e) {
          tryIndex++
        }
      }
      if (returnIndex !== -1) {
        setDevice(result.mainDevices[returnIndex])
        setIsLoading(false)
        return
      }

      const pendingReturnPrinters = result.mainDevices
        ?.filter((printer) => printer.state === 'inactive')
        .sort((a, b) => {
          return (
            new Date(b.entityStartDate).getTime() -
            new Date(a.entityStartDate).getTime()
          )
        })
      if (pendingReturnPrinters?.length > 0) {
        setDevice(pendingReturnPrinters[0])
        setIsLoading(false)
        return
      }
      console.error('No matching printers to display.')
      setIsLoading(false)
      return null
    }
    getPrinter()
  }, [subObj, skuProductMap, deviceCaches, grantClient])

  return {
    subObj,
    device,
    addOns,
    isLoading
  }
}
