import { curry } from 'ramda'
import FetchDataState from '@/types/FetchDataState'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'
import { ActionsType, Action } from '@/actions'
import AppState from '@/types/AppState'
import assignState from '@/helpers/assignState'

const getInitialState = (): AppState => ({
  settings: {
    assetsProvider: AssetsProviderFactory.create(
      process.env.DEFAULT_LANGUAGE,
      process.env.DEFAULT_COUNTRY
    )
  },
  hpOneSubscriptionsInfo: {
    isFetching: true
  },
  instantInkSubscriptionInfo: {
    isFetching: false
  },

  deviceCachesInfo: {
    isFetching: true
  },
  deviceGrantInfo: {
    isFetching: true
  }
})

const factorySuccessFetch = <T>(data: T): FetchDataState<T, undefined> => ({
  isFetching: false,
  data
})

const factorErrorFetch = <T>(error: T): FetchDataState<undefined, T> => ({
  isFetching: false,
  error
})

const hponeSubscriptionReducer = (
  state: AppState,
  action: Action
): AppState => {
  const assignPopuled = curry(assignState)(state)

  switch (action.type) {
    case ActionsType.SET_ASSETS_PROVIDER:
      return assignPopuled('settings.assetsProvider', action.data, false)

    case ActionsType.SET_IS_FETCHING: {
      const { key, value } = action.data
      return assignPopuled(`${key}.isFetching`, value, false)
    }

    case ActionsType.SET_SUCCESS_FETCH: {
      const { key, data } = action.data
      return assignPopuled(key, factorySuccessFetch(data), false)
    }

    case ActionsType.SET_ERROR_FETCH: {
      const { key, error } = action.data
      return assignPopuled(key, factorErrorFetch(error), false)
    }

    default:
      return state
  }
}

export const initialState = getInitialState()

const noHPOneSubscriptions = (state: AppState) =>
  !state.hpOneSubscriptionsInfo?.data ||
  !state.hpOneSubscriptionsInfo?.data?.length

export const selectIsEmpty = (state: AppState) => {
  if (
    state.hpOneSubscriptionsInfo.isFetching ||
    state.instantInkSubscriptionInfo.isFetching
  )
    return false
  if (
    state.hpOneSubscriptionsInfo?.error ||
    state.instantInkSubscriptionInfo?.error
  )
    return false
  if (
    noHPOneSubscriptions(state) &&
    !state.instantInkSubscriptionInfo?.data[0]?.device
  )
    return true
  return false
}

export const selectHPOneSubscriptionsProductSku = (
  state: AppState
): string[] => {
  if (noHPOneSubscriptions(state)) return []
  return state.hpOneSubscriptionsInfo.data.reduce((accumulate, subObj) => {
    const skus =
      subObj.entities.reduce((pre, cur) => {
        if (cur?.product?.value?.productSku) {
          pre.push(cur?.product?.value?.productSku)
        }
        return pre
      }, []) || []
    return accumulate.concat(skus)
  }, [])
}

export const selectHPOneSubscriptionsParentSku = (
  state: AppState
): string[] => {
  if (noHPOneSubscriptions(state)) return []
  const pSkus = new Set<string>()
  state.hpOneSubscriptionsInfo.data.forEach((subObj) => {
    subObj.entities.forEach((cur) => {
      if (cur?.product?.value?.parentProductSku) {
        pSkus.add(cur?.product?.value?.parentProductSku as string)
      }
    })
  })
  return Array.from(pSkus)
}

export default hponeSubscriptionReducer
