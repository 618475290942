import React, { ReactElement, useEffect } from 'react'
import {
  useLDClient,
  ProviderConfig,
  LDProvider
} from 'launchdarkly-react-client-sdk'
import useShellContext from '@/hooks/useShellContext'
import { getStack } from '@/helpers/getStack'

// Launch Darkly Monetization Client-side IDs
const idMap = {
  local: '61a0ce4cb067d50e9a0ee06c',
  dev: '61a0ce4c3ad3b20edf34d8d3',
  pie: '61a0ce4cb067d50e9a0ee06c',
  stage: '61a0ce4c07d4820f48ae978d',
  pro: '61a0ce4cfed8e20fcd63ef59'
}
interface LaunchDarklyProps {
  children: ReactElement<any, any>
}

export const ConfiglessLDProvider = ({ children }: LaunchDarklyProps) => {
  const ldClient = useLDClient()
  const { authProvider } = useShellContext()
  useEffect(() => {
    const identifyUser = async () => {
      const access_token = await authProvider.getAccessToken()
      ldClient.identify(
        {
          key: access_token as string
        },
        'base64'
      )
    }
    if (ldClient) identifyUser()
  }, [ldClient])

  return children
}

export const getLDConfig = (stack): ProviderConfig => {
  return {
    clientSideID:
      process.env.LAUNCHDARKLY_CLIENTSIDE_ID || idMap[getStack(stack)],
    options: {
      bootstrap: 'localStorage',
      streaming: false
    },
    reactOptions: {
      useCamelCaseFlagKeys: true
    }
  }
}

export const LaunchDarklyProvider = ({ stack, children }) => {
  const config = getLDConfig(stack)
  return (
    <LDProvider {...config}>
      <ConfiglessLDProvider>{children}</ConfiglessLDProvider>
    </LDProvider>
  )
}
