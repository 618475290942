import { isInstantInk } from '@/helpers/uiConvert'
import { AnalyticsEventType } from '@/types/AnalyticsEvent'
import {
  Device,
  mblEntityType,
  SubscriptionEntity,
  SubscriptionType
} from '@/types/Subscription'
import { InstantInkSubscription } from '@/types/subscription-response'
import _ from 'lodash'
const activity = 'AcctMgmt-v01'
const screenPath = '/SubscriptionManagementReact/'
const screenName = 'Subscriptions'
const eventDetailVersion = '1.3.0'

type extraProps = {
  controlDetail?: string
  actionParams?: Record<string, any>
  subscription?: SubscriptionType | InstantInkSubscription
  device?: Device
}
export const publishEvent = (
  event: Partial<AnalyticsEventType>,
  extraInfo: extraProps = null
) => {
  const publishCdmEvents = window?.Shell?.v1?.analytics?.publishCdmEvents

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }
  let actionAuxParams = {}
  if (extraInfo?.actionParams) {
    actionAuxParams = extraInfo.actionParams
  }

  if (extraInfo?.controlDetail) {
    event.controlDetail = extraInfo.controlDetail
  }

  if (extraInfo?.subscription) {
    event.controlDetail = findSubscriptionTypeForControlDetail(
      extraInfo.subscription
    )
    actionAuxParams['subscriptionId'] = getSubscriptionIdBySubscription(
      extraInfo.subscription
    )
  }

  if (extraInfo?.device) {
    actionAuxParams['associatedDeviceUuid'] = extraInfo?.device.uuid
    actionAuxParams['associatedDeviceProductNumber'] =
      extraInfo?.device.productNumber
  }

  if (!_.isEmpty(actionAuxParams)) {
    event.actionAuxParams = buildAnalyticsQueryString(actionAuxParams)
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ])
}

export const buildAnalyticsQueryString = (props) => {
  return decodeURIComponent(new URLSearchParams(props).toString())
}

export const findSubscriptionTypeForControlDetail = (
  subscription: SubscriptionType | InstantInkSubscription
) => {
  if (isInstantInk(subscription)) return 'InstantInk'

  const entity = (subscription as SubscriptionType).entities.find(
    (entity: SubscriptionEntity) =>
      entity.entityType === mblEntityType.pc ||
      entity.entityType === mblEntityType.chromebook ||
      entity.entityType === mblEntityType.printer
  )

  return getDeviceTypeForControlDetail(entity?.entityType || '')
}

export const getDeviceTypeForControlDetail = (input: string) => {
  switch (input) {
    case mblEntityType.pc:
    case mblEntityType.chromebook:
      return 'HpOnePc'
    case mblEntityType.printer:
      return 'HpOnePrint'
  }
  return 'InstantInk'
}
// Events

export const getSubscriptionIdBySubscription = (
  subscription: SubscriptionType | InstantInkSubscription
) => {
  if (isInstantInk(subscription)) {
    const instantInk = subscription as InstantInkSubscription
    return instantInk?.accountIdentifier || instantInk?.printerCloudIdentifier
  }

  return (subscription as SubscriptionType).friendlySubscriptionId
}
export const SubscriptionScreenDisplayed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ScreenDisplayed',
  screenPath,
  screenName,
  version: eventDetailVersion
}

export const ModuleDisplayed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ModuleDisplayed',
  screenPath,
  screenName,
  controlName: 'Subscription',
  version: eventDetailVersion
}

export const SubscriptionAccordionExpanded: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlAccordianExpanded',
  screenPath,
  screenName,
  controlName: 'Subscription',
  version: eventDetailVersion
}
export const SubscriptionAccordionCollapsed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlAccordianCollapsed',
  screenPath,
  screenName,
  controlName: 'Subscription',
  version: eventDetailVersion
}

export const CancelSubscriptionButtonClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName,
  controlName: 'CancelSubscription',
  version: eventDetailVersion
}

export const ManageSubscriptionButtonClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName,
  controlName: 'ManageSubscription',
  version: eventDetailVersion
}

export const EditPrinterLinkClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName,
  controlName: 'EditPrinterName',
  version: eventDetailVersion
}
