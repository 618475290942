import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import useDataFetcher from '@/hooks/useDataFetcher'

const useDeviceCachesInfo = () => {
  const subscriptionClient = useSubscriptionClient()
  return useDataFetcher(
    'deviceCachesInfo',
    subscriptionClient?.retrieveDeviceCaches
      ? subscriptionClient.retrieveDeviceCaches.bind(subscriptionClient)
      : null
  )
}

export default useDeviceCachesInfo
