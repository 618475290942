import { useEffect, useState } from 'react'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { PaginatedResponse } from '@/types/pendingChanges'
import { PendingChangesClient } from '@/services/PendingChanges/PendingChangesClient'
import useShellContext from '@/hooks/useShellContext'

type PendingChangesResponse = {
  info: PaginatedResponse
  error: boolean
  isLoading: boolean
}

export const usePendingChangesInfo = (
  subscriptionId: string
): PendingChangesResponse => {
  const { authProvider, stack } = useShellContext()
  const pendingChangesClient = new PendingChangesClient(authProvider, stack)

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getPaymentMethodsInfo = async () => {
      try {
        const { data } = await pendingChangesClient.getPendingChanges(
          subscriptionId
        )
        setInfo(data)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from payment-methods api: ` +
            JSON.stringify(err)
        )
        setError(true)
      }
      setIsLoading(false)
    }

    if (subscriptionId) {
      getPaymentMethodsInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId])

  return {
    info,
    error,
    isLoading
  }
}
