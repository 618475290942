import { ManagementLinks } from '@/components/ManagementLinks/ManagementLinks'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import { formatStringDate } from '@/helpers/formatStringDate'

import useSettings from '@/hooks/useSettings'
import React from 'react'
import { ManagementLinkStyle, SubscriptionDesc } from './styles'
import Skeleton from '../Skeleton'
import { PaymentInfoStyle } from '../shared/PaymentInfo/styles'
import {
  EPaymentType,
  PaymentTypeIcon,
  IPaymentInfoType,
  checkPaymentInfoType
} from '../shared/PaymentInfo/helper'
import { getDeviceFromSubscription } from '@/helpers/uiConvert'
import useGetText from '@/hooks/useGetText'
import { SubscriptionStateResponse } from 'src/types/subscription-state'
export function HPOneSubscriptionHeader(props) {
  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const locale = `${language}-${country}`
  const getText = useGetText('SubscriptionManagement')

  const {
    subscription,
    billingDate,
    billingDateLoading,
    device,
    paymentMethodDetails,
    paymentMethodDetailsIsLoading
  } = useSubscriptionDetails()

  if (paymentMethodDetailsIsLoading) {
    return <Skeleton width="60vw" height="5vh" />
  }

  const currentDevice = getDeviceFromSubscription(
    subscription as SubscriptionStateResponse
  )
  const paymentDetails = !paymentMethodDetailsIsLoading
    ? checkPaymentInfoType(paymentMethodDetails?.payment_method_details)
    : ({} as IPaymentInfoType)

  const nextPaymentDue =
    !billingDateLoading && billingDate
      ? `${getText('nextPaymentDue')}: ${formatStringDate(billingDate, locale)}`
      : ''

  const subscriptionPaymentInfo = (
    <PaymentInfoStyle>
      <div className="paymentInfo-top">
        {device?.friendlyName ? (
          <span>{device.friendlyName}</span>
        ) : (
          device?.name && <span>{device.name}</span>
        )}

        {nextPaymentDue && <span>{nextPaymentDue}</span>}
      </div>
      <div className="paymentInfo-bottom">
        <div className="pib-left">
          <ManagementLinkStyle>
            <ManagementLinks
              {...props}
              currentDevice={currentDevice}
              country={country}
              language={language}
            />
          </ManagementLinkStyle>
        </div>
        <div className="pib-right">
          {paymentMethodDetailsIsLoading ? (
            <img src={PaymentTypeIcon.placeholder} alt="placeholder" />
          ) : (
            <>
              {paymentDetails?.logo}
              {paymentDetails?.paymentMethodType === EPaymentType.CREDITCARD &&
                paymentDetails?.lastFourEl}
            </>
          )}
        </div>
      </div>
    </PaymentInfoStyle>
  )
  return (
    <>
      <SubscriptionDesc className="caption">
        {subscriptionPaymentInfo}
      </SubscriptionDesc>
    </>
  )
}
