import React, { ReactElement, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'
import {
  SubscriptionStateNotif,
  Title,
  LinkWrapperElement,
  LinkElement
} from './styles'
import useGetText from '@/hooks/useGetText'
import {
  SubscriptionReturnStatusEnum,
  SubscriptionStateEnum
} from '../../types/Subscription'
import { getNumOfDaysLeft } from '../../utils/subscriptionState'
import useSubscriptionProducts from '@/hooks/useSubscriptionProducts'
import { getSkuBasedProduct } from '@/utils/getSkuBasedProduct'
import useCancelationInfo from '@/hooks/useCancelationInfo'
import { formatDaysToReturn } from '@/utils/formatDaysToReturn'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import { usePendingChangesInfo } from '@/hooks/usePedingChangesInfo/usePendingChangesInfo'

export default function SubscriptionStateNotification(props) {
  const [isNotificationClosed, setIsNotificationClosed] = useState(false)
  const getText = useGetText('SubscriptionManagement')
  const {
    mainEntity,
    subscriptionState,
    mblEntityType,
    subscription,
    billingDate,
    returnDetails,
    returnDetailsLoading
  } = props
  const { products } = useSubscriptionProducts()
  const { isLoading } = useCancelationInfo({
    sId: subscription.subscriptionId,
    eId: mainEntity?.entityId
  })
  const { info: pendingChangesInfo } = usePendingChangesInfo(
    subscription.subscriptionId
  )
  const filteredPendingChanges = !!pendingChangesInfo?.contents?.find(
    ({ reason }) => reason.type === 'cancellationWithinTrialPeriod'
  )
  const { displayRescindCancel } = useFlags()
  const rescindCancelHref = `/ucde/hp-all-in-print-plan/cancellation/${subscription.subscriptionId}?rescindCancel=true`

  const handleRescindCancel = (): void => {
    props.history.push(
      `/${props.country}/${props.language}${rescindCancelHref}`
    )
  }

  const productType =
    mainEntity?.product?.value?.productType === 'chromebook'
      ? 'pc'
      : mainEntity?.product?.value?.productType

  const getRemorsePeriodTitle = (returnStatus) => {
    const skuBasedProduct = getSkuBasedProduct(
      products,
      mainEntity?.product?.value?.parentProductSku
    )

    if (returnStatus && returnDetails) {
      const numOfDaysLeft = getNumOfDaysLeft(
        returnDetails?.createdAt,
        skuBasedProduct
      )
      switch (returnStatus) {
        case SubscriptionReturnStatusEnum.ITEMS_RECEIVED:
          return filteredPendingChanges
            ? getText(
                `notification.deactivating.${returnStatus}.inRemorsePeriod.title.${productType}`
              )
            : getText(
                `notification.deactivating.${returnStatus}.afterRemorsePeriod.title.${productType}`
              )
        case SubscriptionReturnStatusEnum.NEW:
        case SubscriptionReturnStatusEnum.INITIATED:
          return getText(
            `notification.deactivating.${returnStatus}.title.${productType}`
          )
        case SubscriptionReturnStatusEnum.PROCESSING:
          if (numOfDaysLeft) {
            const daysRemainingToReturn = numOfDaysLeft?.toString()
            const titleKey = `notification.deactivating.${returnStatus}.title.${productType}`
            return getText(titleKey, { daysRemainingToReturn })
          }
          return getText(`notification.inactive.misc.title.${productType}`)
        case SubscriptionReturnStatusEnum.COMPLETE_SUCCESSFUL:
        case SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT:
        case SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL:
        case SubscriptionReturnStatusEnum.TIMEOUT:
          return (
            <Markdown>
              {getText(
                `notification.inactive.${returnStatus}.title.${productType}`
              )}
            </Markdown>
          )
        default: {
          return 'Invalid Status'
        }
      }
    } else {
      if (
        subscriptionState === SubscriptionStateEnum.DEACTIVATING &&
        !returnStatus
      ) {
        return getText(`notification.deactivating.lastDayToReturn.title`)
      }

      return getText(`notification.canceled.title.${productType}`)
    }
  }

  const getRemorsePeriodDescription = (returnStatus): ReactElement | string => {
    const serialNumber = returnDetails?.parts?.find(
      (part) => part.modelSku === mainEntity?.product?.value?.productSku
    )?.serialNumber

    const skuBasedProduct = getSkuBasedProduct(
      products,
      mainEntity?.product?.value?.parentProductSku
    )
    const billingDateFormatted = moment(billingDate?.split('T')[0]).format(
      'MMM DD'
    )
    const lastDayToReturnFormatted = formatDaysToReturn(
      billingDateFormatted,
      skuBasedProduct?.hp_max_days_to_return || 10
    )

    if (returnStatus) {
      switch (returnStatus) {
        case SubscriptionReturnStatusEnum.ITEMS_RECEIVED:
          return filteredPendingChanges ? (
            <Markdown>
              {getText(
                `notification.deactivating.${returnStatus}.inRemorsePeriod.description.${productType}`
              )}
            </Markdown>
          ) : (
            <Markdown>
              {getText(
                `notification.deactivating.${returnStatus}.afterRemorsePeriod.description.${productType}`
              )}
            </Markdown>
          )
        case SubscriptionReturnStatusEnum.NEW:
        case SubscriptionReturnStatusEnum.INITIATED:
          return (
            <Markdown>
              {getText(
                `notification.deactivating.${returnStatus}.description.${productType}`
              )}
            </Markdown>
          )
        case SubscriptionReturnStatusEnum.PROCESSING:
          return (
            <Markdown>
              {getText(
                `notification.deactivating.${returnStatus}.description.${productType}`,
                {
                  serialNumber: returnDetails && serialNumber
                }
              )}
            </Markdown>
          )
        case SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT:
        case SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL:
        case SubscriptionReturnStatusEnum.TIMEOUT:
          return (
            <Markdown>
              {getText(
                `notification.inactive.${returnStatus}.description.${productType}`
              )}
            </Markdown>
          )
        default: {
          return ''
        }
      }
    } else {
      if (
        subscriptionState === SubscriptionStateEnum.DEACTIVATING &&
        !returnStatus
      ) {
        return (
          <>
            <Markdown>
              {getText(
                `notification.deactivating.lastDayToReturn.description`,
                {
                  billingDate: billingDateFormatted,
                  lastDayToReturn: lastDayToReturnFormatted
                }
              )}
            </Markdown>

            {displayRescindCancel && (
              <LinkWrapperElement>
                {getText(
                  `notification.deactivating.lastDayToReturn.rescindCancel1`
                )}
                &nbsp;
                <LinkElement onClick={handleRescindCancel}>
                  {getText(
                    `notification.deactivating.lastDayToReturn.rescindCancel2`
                  )}
                </LinkElement>
              </LinkWrapperElement>
            )}
          </>
        )
      }
      return ''
    }
  }

  const getNotificationTitle = (mainEntity) => {
    const returnStatus = returnDetails?.parts?.filter(
      (part) => part?.modelSku === mainEntity?.product?.value?.productSku
    )[0]?.status
    switch (subscriptionState) {
      case SubscriptionStateEnum.DEACTIVATING:
      case SubscriptionStateEnum.RETURNED:
      case SubscriptionStateEnum.INACTIVE:
        return getRemorsePeriodTitle(returnStatus)
      default:
        return 'Something went Wrong, could not get order Status'
    }
  }
  const getNotificationDescription = (mainEntity): ReactElement | string => {
    const returnStatus = returnDetails?.parts?.find(
      (part) => part.modelSku === mainEntity?.product?.value?.productSku
    )?.status
    switch (subscriptionState) {
      case SubscriptionStateEnum.DEACTIVATING:
      case SubscriptionStateEnum.RETURNED:
      case SubscriptionStateEnum.INACTIVE:
        return getRemorsePeriodDescription(returnStatus)
      default:
        return ''
    }
  }

  const getNotificationType = (): string | undefined => {
    if (!isLoading) {
      const returnStatus = returnDetails?.parts?.find(
        (part) => part?.modelSku === mainEntity?.product?.value?.productSku
      )?.status
      if (
        subscriptionState === 'deactivating' &&
        (returnStatus === 'new' || returnStatus === 'initiated')
      )
        return 'informative'
      if (
        (subscriptionState === 'inactive' ||
          subscriptionState === 'returned' ||
          subscriptionState === 'deactivating') &&
        ['completeSuccessful', 'itemReceived'].includes(returnStatus)
      ) {
        return 'informative'
      }
      const validStatuses = Object.values(SubscriptionReturnStatusEnum)
      if (
        (subscriptionState === 'deactivating' ||
          subscriptionState === 'inactive') &&
        !validStatuses.includes(returnStatus as SubscriptionReturnStatusEnum)
      ) {
        return 'informative'
      }
      return 'negative'
    }
  }
  const notificationTitleMap = {
    received: {
      title: {
        [mblEntityType.pc]: getText('notification.received.pc'),
        [mblEntityType.chromebook]: getText('notification.received.pc'),
        [mblEntityType.printer]: getText('notification.received.printer'),
        [mblEntityType.hybrid]: getText('notification.received.hybrid')
      },
      notificationType: 'informative'
    },
    deactivating: {
      title: {
        [mblEntityType.chromebook]: getNotificationTitle(mainEntity),
        [mblEntityType.pc]: getNotificationTitle(mainEntity),
        [mblEntityType.printer]: getNotificationTitle(mainEntity),
        [mblEntityType.hybrid]: getNotificationTitle(mainEntity)
      },
      notificationType: getNotificationType(),
      description: {
        [mblEntityType.pc]: getNotificationDescription(mainEntity),
        [mblEntityType.chromebook]: getNotificationDescription(mainEntity),
        [mblEntityType.printer]: getNotificationDescription(mainEntity),
        [mblEntityType.hybrid]: getNotificationDescription(mainEntity)
      }
    },
    returned: {
      title: {
        [mblEntityType.pc]: getNotificationTitle(mainEntity),
        [mblEntityType.chromebook]: getNotificationTitle(mainEntity),
        [mblEntityType.printer]: getNotificationTitle(mainEntity),
        [mblEntityType.hybrid]: getNotificationTitle(mainEntity)
      },
      notificationType: getNotificationType()
    },
    cancelled_not_received: {
      title: {
        [mblEntityType.pc]: getText(
          'notification.canceled.unsuccessful.title.pc'
        ),
        [mblEntityType.chromebook]: getText(
          'notification.canceled.unsuccessful.title.pc'
        ),
        [mblEntityType.printer]: getText(
          'notification.canceled.unsuccessful.title.printer'
        ),
        [mblEntityType.hybrid]: getText(
          'notification.canceled.unsuccessful.title.hybrid'
        )
      },
      notificationType: 'negative'
    },
    inactive: {
      title: {
        [mblEntityType.pc]: getNotificationTitle(mainEntity),
        [mblEntityType.chromebook]: getNotificationTitle(mainEntity),
        [mblEntityType.printer]: getNotificationTitle(mainEntity),
        [mblEntityType.hybrid]: getNotificationTitle(mainEntity)
      },
      notificationType: getNotificationType(),
      description: {
        [mblEntityType.pc]: getNotificationDescription(mainEntity),
        [mblEntityType.chromebook]: getNotificationDescription(mainEntity),
        [mblEntityType.printer]: getNotificationDescription(mainEntity),
        [mblEntityType.hybrid]: getNotificationDescription(mainEntity)
      }
    },
    pending: {
      title: {
        [mblEntityType.pc]: (
          <>
            <b>{getText('notification.pending.title.pc')} </b>
            <p className="description">
              {getText('notification.pending.description', {
                device: 'laptop'
              })}
            </p>
          </>
        ),
        [mblEntityType.chromebook]: (
          <>
            <b>{getText('notification.pending.title.pc')} </b>
            <p className="description">
              {getText('notification.pending.description', {
                device: 'laptop'
              })}
            </p>
          </>
        ),
        [mblEntityType.printer]: (
          <>
            <b>{getText('notification.pending.title.printer')} </b>
            <p className="description">
              {getText('notification.pending.description', {
                device: 'printer'
              })}
            </p>
          </>
        ),
        [mblEntityType.hybrid]: (
          <>
            <b>{getText('notification.pending.title.hybrid')} </b>
            <p className="description">
              {getText('notification.pending.description', {
                device: 'device'
              })}
            </p>
          </>
        )
      },
      notificationType: 'positive'
    }
  }
  const showNotificationCloseIcon = subscriptionState === 'pending'
  const getFormattedTitle = (): ReactElement => {
    const title =
      notificationTitleMap[subscriptionState]?.title[mainEntity?.entityType]
    return <Title>{title}</Title>
  }

  const getDescription = (): ReactElement | string => {
    const desc = notificationTitleMap[subscriptionState].description
      ? notificationTitleMap[subscriptionState]?.description[
          mainEntity?.entityType
        ]
      : ''
    return desc || ''
  }
  if (isLoading || returnDetailsLoading || isNotificationClosed) {
    return null
  }

  return (
    <SubscriptionStateNotif>
      <InlineNotification
        closeButton={showNotificationCloseIcon}
        id={'notification'}
        title={getFormattedTitle()}
        hideIcon={false}
        type={notificationTitleMap[subscriptionState].notificationType}
        description={getDescription()}
        onClose={() => {
          setIsNotificationClosed(true)
        }}
      />
    </SubscriptionStateNotif>
  )
}
