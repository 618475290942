export enum breakpoints {
  mobileXS = 'MOBILEXS',
  mobile = 'MOBILE',
  tablet = 'TABLET',
  desktop = 'DESKTOP',
  ultraWide = 'ULTRAWIDE'
}

const deviceSize = {
  mobileXS: '375px',
  mobileXSMax: '499px',
  mobile: '500px',
  mobileMax: '640px',
  tablet: '641px',
  tabletMax: '1007px',
  desktop: '1008px',
  desktopMax: '1737px',
  ultraWide: '1738px'
}

const createMaxQuery = (querySize: string) => `(max-width: ${querySize})`
const device = {
  mobileXS: createMaxQuery(deviceSize.mobileXSMax),
  mobile: createMaxQuery(deviceSize.mobileMax),
  tablet: createMaxQuery(deviceSize.tabletMax),
  desktop: createMaxQuery(deviceSize.desktopMax),
  ultraWide: createMaxQuery(deviceSize.ultraWide)
}

// media queries
const smartQueries = {
  mobileXS: (css) => `
  @media screen and (max-width: ${deviceSize.mobileXSMax}) {
    ${css}
  }
  `,
  mobile: (css) => `
  @media screen and (min-width: ${deviceSize.mobile}) and (max-width: ${deviceSize.mobileMax}){
    ${css}
  }
  `,
  tablet: (css) => `
  @media screen and (min-width: ${deviceSize.tablet}) and (max-width: ${deviceSize.tabletMax}) {
    ${css}
  }
  `,
  desktop: (css) => `
  @media screen and (min-width: ${deviceSize.desktop}) and (max-width: ${deviceSize.desktopMax}) {
    ${css}
  }
  `,
  ultraWide: (css) => `
  @media screen and (min-width: ${deviceSize.ultraWide}) {
    ${css}
  }
  `
}

// use with useDeviceWidth in component
const smartWidth = (width) => {
  let w = 0

  try {
    w = parseInt(width)
  } catch (err) {
    // eslint-disable-next-line no-empty
  }

  if (w > parseInt(deviceSize.desktopMax)) {
    // w > 1199
    return breakpoints.ultraWide
  }

  if (w > parseInt(deviceSize.tabletMax)) {
    // 1199 >= w > 991
    return breakpoints.desktop
  }

  if (w > parseInt(deviceSize.mobileMax)) {
    // 991 >= w > 576
    return breakpoints.tablet
  }

  if (w > parseInt(deviceSize.mobileXSMax)) {
    // 991 >= w > 576
    return breakpoints.mobile
  }

  if (w > 0) {
    // 576 >= w > 0
    return breakpoints.mobileXS
  }

  return width
}

export { deviceSize, smartQueries, smartWidth }

export default device
