declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Shell: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Portal: any
  }
}

export function forceLogin() {
  if (window.Portal) {
    window.Portal.auth.forceLogin()
  } else if (window.Shell) {
    window.Shell.v1.authProvider.forceLogin()
  }
}

export const reloginOnUnauthorized = (error) => {
  if (error.isAxiosError && error.response.status === 403) {
    const lastErrorTimestamp = localStorage.getItem('last-403-error-timestamp')
    const time = Number(lastErrorTimestamp)
    if (!isNaN(time)) {
      const currentTime = Date.now()
      // force relogin if more than 10 minutes passed before the last relogin
      if ((currentTime - time) / (1000 * 60) > 10) {
        localStorage.setItem('last-403-error-timestamp', Date.now().toString())
        forceLogin()
      }
    } else {
      // Date is wrong or date does not exist
      localStorage.setItem('last-403-error-timestamp', Date.now().toString())
      // force refresh
      forceLogin()
    }
  }
}
