import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { highContrastGray } = tokens.color

interface StyledHeaderActionProps {
  isDisabled?: boolean
}

export const StyledHeaderActionButton = styled.button<StyledHeaderActionProps>`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  color: ${(props) => (props.isDisabled ? highContrastGray : '#0278ab')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

  &:hover {
    text-decoration: none;
  }
  &:focus {
    border: 1px solid #0278ab;
  }
`

export const TextDividerStyle = styled.span`
  padding: 0 ${tokens.layout.size1};
  font-weight: 400;
  font-size: ${tokens.typography.size2} !important;
  line-height: ${tokens.typography.lineHeight2};
`
