import imgAmex from './img-amex.svg'
import imgCardPlaceholder from './img-card-placeholder.svg'
import imgCitizensPay from './img-citizensPay.svg'
import imgCitizensPayGray from './img-citizensPayGray.svg'
import imgDiscover from './img-discover.svg'
import imgGoogleOne from './img-google1.svg'
import imgHpOne from './img-hpone.svg'
import imgInstantInk from './img-instant-ink.svg'
import imgKeyboard from './img-keyboard.svg'
import imgLaptop from './img-laptop.svg'
import imgMastercard from './img-mastercard.svg'
import imgMouse from './img-mouse.svg'
import imgPayPal from './img-paypal.svg'
import imgPrinter from './img-printer.svg'
import imgVisa from './img-visa.svg'
import imgInstantInk2 from './img-instantink.svg'
import printerPlaceholder from './printer-placeholder.svg'

const Images = {
  imgAmex,
  imgCitizensPayGray,
  imgCardPlaceholder,
  imgCitizensPay,
  imgDiscover,
  imgGoogleOne,
  imgHpOne,
  imgInstantInk,
  imgKeyboard,
  imgLaptop,
  imgMastercard,
  imgMouse,
  imgPayPal,
  imgPrinter,
  imgVisa,
  imgInstantInk2,
  printerPlaceholder
}

export default Images
