import {
  mblEntityType,
  AddOn as UIAddOn,
  Device as UIDevice
} from '@/components/shared/types/subscription'
import {
  AddOn,
  Device,
  DeviceCache,
  deviceGroup,
  mblEntityType as entityTypeEnum,
  SubscriptionEntity,
  SubscriptionStateEnum,
  SubscriptionType
} from '@/types/Subscription'
import { InstantInkSubscription } from '@/types/subscription-response'
import isLeapYear from 'leap-year'
import {
  BundleItem,
  BundleItemOption,
  BundleProduct,
  GetProductsByParentSkuQuery,
  ProductInterface
} from 'src/__generated__/graphql'
import { formatStringDate } from './formatStringDate'
import { Entity, SubscriptionStateResponse } from '@/types/subscription-state'

export const checkDeviceType = (input: string) => {
  switch (input) {
    case entityTypeEnum.pc:
    case entityTypeEnum.chromebook:
    case entityTypeEnum.printer:
      return deviceGroup.MAINDEVICE
    case entityTypeEnum.keyboard:
    case entityTypeEnum.mouse:
    case entityTypeEnum.pen:
      return deviceGroup.ACCESSORY
    case entityTypeEnum.printplan:
      return deviceGroup.PRINTPLAN
  }
  return deviceGroup.OTHER
}
export const convertDevice = (device: Device, locale: string): UIDevice => {
  if (device) {
    return {
      ...device,
      type: device.type as mblEntityType,
      eligibleForRefresh: device.eligibleForRefresh
        ? formatStringDate(device.eligibleForRefresh, locale)
        : null
    }
  } else return null
}

export const convertAddOns = (addOns: AddOn[], locale: string): UIAddOn[] => {
  if (addOns) {
    return addOns
      .filter((e) => e)
      .map((e) => ({
        ...e,
        type: e.type as mblEntityType,
        entityStartDate: e?.entityStartDate
          ? formatStringDate(e?.entityStartDate, locale)
          : null
      }))
  } else return null
}

export const findDeviceCacheById = (
  deviceCacheId: string,
  deviceCaches: DeviceCache[]
): DeviceCache | undefined => {
  if (!deviceCaches) return undefined
  return deviceCaches.find((dev) => dev?.deviceId === deviceCacheId)
}

export const getSkuProductMap = (
  productData: GetProductsByParentSkuQuery
): Map<string, ProductInterface> => {
  if (!productData) return null
  const skuProductMap = new Map<string, ProductInterface>()
  productData?.products?.items.forEach((bundleProduct: BundleProduct) => {
    bundleProduct?.items.forEach((bundleItem: BundleItem) => {
      bundleItem?.options.forEach((bundleItemOption: BundleItemOption) => {
        const { product } = bundleItemOption
        if (product.sku) {
          skuProductMap.set(product.sku, product)
        }
      })
    })
  })
  return skuProductMap
}

export const getBundleProduct = (
  productData: GetProductsByParentSkuQuery
): BundleProduct[] => {
  if (!productData) return null
  return productData?.products?.items.map((bundleProduct: BundleProduct) => {
    const { items: _, ...rest } = bundleProduct
    return rest
  })
}

export const convertDeviceCacheToDevice = (
  deviceCache: DeviceCache,
  entityType: string,
  isInstantInk = false
): Device => {
  const device = <Device>{ type: entityType }
  if (deviceCache?.deviceId) {
    device.uuid = deviceCache?.deviceId
  }
  if (deviceCache?.identity?.makeAndModel?.series) {
    device['name'] = deviceCache?.identity?.makeAndModel?.series
  }
  if (deviceCache?.identity?.friendlyName) {
    device.friendlyName = deviceCache?.identity?.friendlyName
  }
  if (deviceCache?.identity?.serialNumber) {
    device.serialNumber = deviceCache?.identity?.serialNumber
  }
  if (deviceCache?.images && deviceCache?.images.length > 0 && isInstantInk) {
    device.image = deviceCache.images.slice(-1)[0].url
  }
  if (deviceCache?.identity?.makeAndModel?.number) {
    device.productNumber = deviceCache?.identity?.makeAndModel?.number
  }

  return device
}

export const convertProductToDevice = (
  product: ProductInterface,
  entityType: string
): Device => {
  const device = <Device>{ type: entityType }
  if (product?.name || product?.hp_short_config_name) {
    device['name'] = product?.hp_short_config_name || product?.name
  }
  if (product?.thumbnail && product?.thumbnail?.url) {
    device.image = product?.thumbnail?.url
  } else if (product?.image && product?.image?.url) {
    device.image = product?.image?.url
  }
  if (product?.sku) {
    device.productNumber = product.sku
  }
  return device
}

export const mergeProductNDeviceCacheEntity = ({
  subObj,
  skuProductMap,
  deviceCaches
}: {
  subObj: SubscriptionStateResponse
  skuProductMap?: Map<string, ProductInterface>
  deviceCaches?: DeviceCache[]
}) => {
  return subObj.entities.reduce(
    (accumulator, entity) => {
      const deviceType = checkDeviceType(entity.entityType)
      if (deviceType === deviceGroup.OTHER) return accumulator
      let deviceInfo = <Device | AddOn>{ type: entity.entityType }
      //get sku, find product
      const sku = entity?.product?.value?.productSku as string
      const product = skuProductMap?.get(sku)
      const deviceCacheId =
        entity?.entityDetails?.uniqueDeviceId ||
        entity?.entityDetails?.accessoryId ||
        entity?.entityDetails?.pendingAssociationId
      if (deviceCacheId) {
        const deviceCache = findDeviceCacheById(deviceCacheId, deviceCaches)
        //convert deviceCache to deviceDetail and set to entity
        deviceInfo = {
          ...deviceInfo,
          entityDetails: entity?.entityDetails,
          ...convertDeviceCacheToDevice(deviceCache, entity.entityType)
        }
      }

      if (deviceType == deviceGroup.MAINDEVICE) {
        deviceInfo = {
          ...convertProductToDevice(product, entity.entityType),
          ...deviceInfo,
          eligibleForRefresh:
            entity?.entityEndDate ||
            refreshDateFromEntityStartDate(entity?.entityStartDate)
        }
        if (entity?.entityDetails?.serialNumber) {
          deviceInfo['serialNumber'] = entity?.entityDetails?.serialNumber
        }
        if (entity?.state) {
          deviceInfo['state'] = entity?.state
        }
        if (entity?.createdAt) {
          deviceInfo['createdAt'] = entity?.createdAt
        }
        accumulator.mainDevices.push(<Device>deviceInfo)
      } else if (deviceType == deviceGroup.PRINTPLAN) {
        deviceInfo = convertProductToDevice(product, entity.entityType)
        if (entity?.entityStartDate)
          deviceInfo['entityStartDate'] = entity?.entityStartDate

        if (product?.hp_pages_per_month) {
          deviceInfo['pagePerMonth'] = product?.hp_pages_per_month
        }
        accumulator.addOns.push(<AddOn>deviceInfo)
      } else {
        deviceInfo = {
          ...convertProductToDevice(product, entity.entityType),
          ...deviceInfo
        }
        accumulator.addOns.push(<AddOn>deviceInfo)
      }
      return accumulator
    },
    { mainDevices: [], addOns: [] }
  )
}

export const selectHPOneSubscriptionOrderId = (
  subObj: SubscriptionType
): number => {
  const cur = subObj.entities.find((entity) => {
    const deviceType = checkDeviceType(entity.entityType)
    return deviceType == deviceGroup.MAINDEVICE
  })
  if (cur?.source?.value?.orderId) {
    return Number(cur.source.value.orderId)
  }
  return null
}

export const getSubscriptionId = (
  subObj: SubscriptionStateResponse
): string | null => {
  const cur = subObj.entities.find((entity) => {
    const deviceType = checkDeviceType(entity.entityType)
    return deviceType == deviceGroup.MAINDEVICE
  })
  if (cur?.commerce?.value?.subscriptionId) {
    return cur.commerce.value.subscriptionId
  }
  return null
}

export const refreshDateFromEntityStartDate = (startDate: string): string => {
  const date = new Date(startDate)
  // If a customer buys a subscription on a leap year (Feb 29), ensure the refreshed Date is Feb 28
  if (isLeapYear(date)) {
    const subscriptionDay = date.getDate()
    const subscriptionMonth = date.getMonth() + 1
    if (subscriptionDay == 29 && subscriptionMonth == 2)
      date.setUTCDate(date.getUTCDate() - 1)
    else date.setUTCDate(date.getUTCDate())
  }
  date.setFullYear(date.getFullYear() + 2)
  return date.toISOString()
}

export const isCancellable = (entity: SubscriptionEntity): boolean => {
  return entity?.cancellation?.enabled ?? false
}

export const isReceived = (entity: SubscriptionEntity): boolean => {
  return entity?.state === 'received'
}
const getReplacedEntity = (
  entities: SubscriptionEntity[]
): SubscriptionEntity | null =>
  entities.reduce((entityAcc, entity) => {
    if (
      (entity.state === 'deactivating' || entity.state === 'inactive') &&
      entity.linkedEntities.find(
        (linkedEntity) =>
          linkedEntity.type === 'replacedBy' ||
          linkedEntity.type === 'replacedWith'
      )
    ) {
      const replaced = entity.linkedEntities.find(
        (item) =>
          item.type === 'replacedBy' &&
          !entity.linkedEntities.some(
            (innerLinkedEntity) => innerLinkedEntity.type === 'replacedWith'
          )
      )
      if (replaced) {
        entityAcc = entity
      }
    } else {
      entityAcc = entity
    }
    return entityAcc
  }, null)

export const getMainEntity = (subscription: SubscriptionType) => {
  const deviceEntities = subscription.entities.filter(
    (entity: SubscriptionEntity) =>
      entity.entityType === entityTypeEnum.pc ||
      entity.entityType === entityTypeEnum.chromebook ||
      entity.entityType === entityTypeEnum.printer
  )
  if (deviceEntities.length === 1) return deviceEntities[0]
  return getReplacedEntity(deviceEntities)
}

export const isInstantInk = (
  subscription: SubscriptionStateResponse | InstantInkSubscription
): boolean => {
  if (subscription) {
    return (
      'accountIdentifier' in subscription ||
      'printerCloudIdentifier' in subscription
    )
  }
}

const getHPOneSubscriptionKey = (subscription: SubscriptionType): string => {
  return `${subscription?.subscriptionId}-${subscription?.hybridIndex}`
}

const getIISubscriptionKey = (subscription: InstantInkSubscription): string => {
  return subscription?.accountIdentifier || subscription?.printerCloudIdentifier
}

export const getSubscriptionKey = (
  subscription: SubscriptionType | InstantInkSubscription
): string => {
  return isInstantInk(subscription)
    ? getIISubscriptionKey(subscription as InstantInkSubscription)
    : getHPOneSubscriptionKey(subscription as SubscriptionType)
}

type ComparisonDataType = {
  state: string
  startDate: string
  type: string
}
const inactiveState: string[] = [SubscriptionStateEnum.INACTIVE]
const entityTypeRank = {
  'iot-pc': 0,
  chromebook: 1,
  'iot-printer': 2,
  InstantInk: 3
}
export const sortAllSubscriptionsBasedOnRules = (
  subscriptions: Array<SubscriptionType | InstantInkSubscription>
): Array<SubscriptionType | InstantInkSubscription> => {
  if (!subscriptions || subscriptions.length < 2) {
    return subscriptions
  }

  return Array.from(subscriptions).sort((a, b) => {
    // Sorting Rules
    // 1: Display cards by status Active has precedent over canceled/inactive
    // 2: Display card by dates (New subscriptions first, older later)
    // 3: In case subscription has same starting date - HP One has precedent over Instant Ink
    // 4: In case subscription has same starting date - HP One Desktop has precedent over HP One Print
    const campareA = getSubscriptionComparisonData(a)
    const campareB = getSubscriptionComparisonData(b)
    let result = 0
    //check cancel state
    let arank = inactiveState.includes(campareA['state']) ? 0 : -1
    let brank = inactiveState.includes(campareB['state']) ? 0 : -1
    result = arank - brank
    if (result !== 0) return result
    //check date
    arank = new Date(campareA['startDate']).getTime()
    brank = new Date(campareB['startDate']).getTime()
    result = brank - arank
    if (result !== 0) return result
    //check type
    arank = entityTypeRank[campareA['type']]
    brank = entityTypeRank[campareB['type']]
    return arank - brank
  })
}

const hardCodedInstantInkStartDate = '2020-01-01T23:40:50.688907Z'
export const getSubscriptionComparisonData = (
  subscription: SubscriptionType | InstantInkSubscription
): ComparisonDataType => {
  if (isInstantInk(subscription)) {
    //TODO we don't have instantInk subscription startDate, we us hardcoded here, replace with real one when we have
    return {
      state: subscription.state,
      startDate: hardCodedInstantInkStartDate,
      type: 'InstantInk'
    }
  } else {
    const hpOneSubscription = subscription as SubscriptionType
    const mainEntity = hpOneSubscription.entities.find(
      (entity: SubscriptionEntity) =>
        entity.entityType === entityTypeEnum.pc ||
        entity.entityType === entityTypeEnum.chromebook ||
        entity.entityType === entityTypeEnum.printer
    )
    return {
      state: mainEntity.state,
      startDate: mainEntity.entityStartDate,
      type: mainEntity.entityType
    }
  }
}

export const getDeviceFromSubscription = (
  subscription: SubscriptionStateResponse
): Entity =>
  subscription.entities.find((entity) => {
    const deviceType = checkDeviceType(entity.entityType)
    return deviceType == deviceGroup.MAINDEVICE
  })
