import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

interface PanelProps {
  isDisabled?: boolean
}

const { lineHeight11, lineHeight7, lineHeight1, lineHeight2, size0 } =
  tokens.typography
const { highContrastGray, gray6, gray9, hpBlue7 } = tokens.color
const { cornerRadius2, size2, size4, size5 } = tokens.layout
export const Container = styled.div`
  color: ${({ theme }) => theme.colorForegroundMedium};
  font-family: ${({ theme }) => theme.fontTextRegular};
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 0;
    > div {
      margin-bottom: ${lineHeight11};
      margin-top: -2.4rem;
    }
  }
  #hpOneIcon {
    width: 105px;
    height: 1rem;
  }
  div[id^='hpOneSubscriptionAccordion-'] {
    margin-bottom: ${lineHeight7};
    margin-top: ${lineHeight7};
    [aria-expanded] {
      svg > path {
        fill: ${hpBlue7};
      }
    }
    > div {
      &:has(div[aria-expanded='false']) {
        border-radius: ${cornerRadius2} ${cornerRadius2} 0 0;
      }
      border-bottom: 1px solid rgba(54, 54, 69, 0.1);
      margin-bottom: 0;
      > div:first-child {
        padding: 1.5rem 1.5rem 1rem 1rem;
      }

      > div:nth-child(2) {
        padding: 0 0.5rem 0.5rem 0;
        margin-bottom: 0.5rem;
      }
      [aria-hidden^='false'] {
        margin: 0px;
        padding: 1rem 1rem;
      }
    }
  }
  div[class='notification'] {
    margin: 0 -1.5rem;
    padding: 0;
    margin-bottom: -1.75rem;
    > div {
      border-radius: 0;
    }
  }

  .header-area {
    display: flex;
    flex-direction: column;
    span {
      font-size: ${size0};
      line-height: ${lineHeight1};
      margin-top: ${size2};
    }
  }
`
export const Panel = styled.div`
  display: flex;
  flex-direction: column;
`
export const AccordionHeader = styled.div`
  font-family: ${({ theme }) => theme.fontTextRegular};
  color: ${({ theme }) => theme.colorForegroundMedium};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`
export const PreArrivalPanel = styled.div<PanelProps>`
  font-size: ${tokens.typography.size2};
  background-color: ${({ theme }) => theme.colorBackground};
  border-radius: ${cornerRadius2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 284px;
  padding: 0px 10px;
  text-align: center;
  line-height: ${lineHeight2};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  > div {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
  }
`
export const DeviceIcon = styled.div<PanelProps>`
  svg {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray6)};
  }
`

export const NotificationWrapper = styled.div`
  padding: 0;
  margin-top: -2.4rem;
  > div {
    padding-left: 2rem;
    align-items: center;
    > div {
      > span:first-child {
        font-weight: bold;
      }
    }
  }
`

export const DelinquencyNotificationStyle = styled.div`
  margin: 0 -1.5rem;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: -0.15rem;
`
export const NotificationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .delinquencySection {
    margin-bottom: -${size4};
  }
  > div:nth-child(2).delinquencySection {
    margin-top: ${size5};
  }
`
