import { AxiosResponse } from 'axios'
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Order, PaginatedOrders } from 'src/types/checkCancellation'
import { reloginOnUnauthorized } from '../../utils/reloginOnUnauthorized'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/fulfillment/v1',
  [Stack.stage]: 'https://stage-us1.api.ws-hp.com/fulfillment/v1',
  [Stack.pie]: 'https://pie-us1.api.ws-hp.com/fulfillment/v1',
  [Stack.dev]: 'https://pie-us1.api.ws-hp.com/fulfillment/v1'
}

export class FulfillmentClient extends StratusClient {
  apiVersion = '1'
  timeout = 0

  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(baseUrl[stack], authToken)
  }

  async getFulfilmentOrderByFulfillmentOrderId(
    fulfillmentOrderId: string,
    subscriptionId?: string,
    productType?: string
  ): Promise<Order[]> {
    let returnData: Order[] = []
    let nextPage = 1
    try {
      do {
        const { data: pageData } = (await this.jarvisWebHttpInstance.get({
          url: '/orders',
          params: {
            fulfillmentOrderId,
            subscriptionId,
            productType,
            page: nextPage,
            size: 20,
            sort: 'createdAt,desc'
          }
        })) as AxiosResponse<PaginatedOrders>

        returnData = [...returnData, ...pageData.content]

        nextPage = pageData.totalPages !== nextPage ? nextPage + 1 : 0
      } while (nextPage > 0)
    } catch (err) {
      reloginOnUnauthorized(err)
      sendOpenTelemetryEvent(
        `Error while fetching fulfillment order by fulfillment order id: ${JSON.stringify(
          err
        )}`
      )
      throw err
    }
    return returnData
  }

  async getFulfillmentOrderByTenantIdAndSubscriptionId(
    tenantId: string,
    subscriptionId: string
  ): Promise<Order[]> {
    try {
      const { data: firstData } = (await this.jarvisWebHttpInstance.get({
        url: `/orders/tenant/${tenantId}`,
        params: {
          subscriptionId,
          page: 1
        }
      })) as AxiosResponse<PaginatedOrders>

      if (firstData.totalPages === 1) {
        return firstData.content
      }

      const pagesPromises = Array.from(
        Array(firstData.totalPages - 1),
        (_, i) =>
          this.jarvisWebHttpInstance.get({
            url: `/orders/tenant/${tenantId}`,
            params: {
              subscriptionId,
              page: i + 2
            }
          })
      )

      let allData = firstData.content
      await Promise.all(pagesPromises).then((pages) => {
        pages.forEach((page) => (allData = [...allData, ...page.data.content]))
      })
      return allData
    } catch (error) {
      reloginOnUnauthorized(error)
      sendOpenTelemetryEvent(
        `Error while fetching fulfillment order from fulfillment client: ${JSON.stringify(
          error
        )}`
      )
      throw error
    }
  }
}
