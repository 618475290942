import React from 'react'
import AppContext from '@/context/AppContext'

const useAppContext = () => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error(
      'useAppContext must be used inside a App Provider component'
    )
  }
  return context
}

export default useAppContext
