import { useEffect, useState } from 'react'
import { getSubscriptionId } from '@/helpers/uiConvert'
import useSubscriptionClient from '../useSubscriptionClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { SubscriptionStateResponse } from 'src/types/subscription-state'

export const useCommerceInfo = ({
  subObj
}: {
  subObj: SubscriptionStateResponse
}) => {
  const client = useSubscriptionClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getCommerceInfo = async () => {
      setIsLoading(true)
      try {
        const data = await client.getCommerceInfo(getSubscriptionId(subObj))
        setInfo(data)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from commerce api : ` + JSON.stringify(err)
        )
        setError(true)
      }
      setIsLoading(false)
    }

    if (subObj) {
      getCommerceInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subObj])

  return {
    data: info,
    error,
    isLoading
  }
}
