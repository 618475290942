import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import useDataFetcher from '@/hooks/useDataFetcher'

const useDeviceGrantInfo = () => {
  const client = useSubscriptionClient()
  return useDataFetcher(
    'deviceGrantInfo',
    client?.getDeviceGrantInfo ? client.getDeviceGrantInfo.bind(client) : null
  )
}

export default useDeviceGrantInfo
