import { useEffect, useState } from 'react'
import { selectHPOneSubscriptionsParentSku } from '@/context/reducer'
import { getBundleProduct } from '@/helpers/uiConvert'
import useAppContext from '@/hooks/useAppContext'
import useGetProductByParentSku from '@/hooks/useGetProductsByParentSku/useGetProductsByParentSku'

const useSubscriptionProducts = (): any => {
  const { state } = useAppContext()
  const parentSkus = selectHPOneSubscriptionsParentSku(state)
  const { data: productData } = useGetProductByParentSku(parentSkus)
  const [products, setProducts] = useState(null)

  useEffect(() => {
    setProducts(getBundleProduct(productData))
  }, [productData])

  return {
    products
  }
}

export default useSubscriptionProducts
