export interface PaymentIssue {
  pendingChangeId: string
  subscriptionId: string
  entityId: string
  type: string
  value: {
    state: string
    at: string
  }
  initiator: {
    type: string
    value: {
      clientId: string
    }
  }
  reason: {
    type: string
    value: {
      attribute_1: string
      attribute_2: string
    }
  }
  createdAt: string
  updatedAt: string
}

export interface PaginatedResponse {
  version: string
  totalCount: number
  maxPaginationLimit: number
  defaultPaginationLimit: number
  contents: PaymentIssue[]
  links: {
    rel: string
    href: string
  }[]
}
export enum EDelinquencyNotification {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  DEACTIVATING = 'deactivating',
  INACTIVE = 'no_longer_active',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRE = 'about_to_expire',
  NONE = 'none',
  RESOLVED = 'resolved'
}
