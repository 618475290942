import Images from '@/assets/images'
import { InstantInkManagementLinks } from '@/components/InstantInkManagementLinks'
import SubscriptionAccordion from '@/components/SubscriptionAccordion'
import { convertDevice } from '@/helpers/uiConvert'
import useSettings from '@/hooks/useSettings'
import { SubscriptionIncludesPanel } from '@/components/shared/SubscriptionIncludesPanel'
import React from 'react'
import {
  AccordionHeader,
  Container,
  Panel,
  SubscriptionDesc,
  PaymentDetails
} from './styles'
import useInstantInkPaymentInfo from '@/hooks/usePaymentMethodsInfo/usePaymentMethodsInfo'
import Skeleton from '@/components/Skeleton'
import { logos } from '@/components/shared/PaymentInfo/helper'
import { PaymentInfoStyle } from '../shared/PaymentInfo/styles'
export default function InstantInkSubscription(props) {
  const {
    data: { device, subscription },
    t
  } = props
  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const locale = `${language}-${country}`
  const { data: info, isLoading } = useInstantInkPaymentInfo()

  if (isLoading) {
    return <Skeleton width="60vw" height="5vh" />
  }
  return (
    <Container>
      {device && (
        <SubscriptionAccordion
          header={{
            centralArea: (
              <>
                <img
                  id="instantInkIcon"
                  src={Images.imgInstantInk2}
                  alt="instantInkIcon"
                ></img>
                <span className="insinksubid">
                  {t('subscriptionId')}: {subscription.accountIdentifier}
                </span>
              </>
            ),
            previewArea: (
              <>
                <AccordionHeader>
                  <SubscriptionDesc className="caption">
                    <PaymentInfoStyle>
                      <div className="paymentInfo-top">
                        {subscription?.printerFriendlyName ? (
                          <span>{subscription.printerFriendlyName}</span>
                        ) : (
                          subscription?.printerModelName && (
                            <span>{subscription.printerModelName}</span>
                          )
                        )}

                        {subscription.nextChargeDate && (
                          <span>
                            {t('nextPaymentDue')}: {subscription.nextChargeDate}
                          </span>
                        )}
                      </div>
                    </PaymentInfoStyle>
                    <div className="links-card">
                      <InstantInkManagementLinks
                        {...props}
                        subscription={subscription}
                        country={country}
                        language={language}
                      />
                      <PaymentDetails>
                        <img
                          className="credit-card-type"
                          src={logos[info?.creditCard?.cardTypeKey]}
                        />
                        {info?.creditCard?.cardNumber ? (
                          <div className="last-4-digits">
                            XXXX-
                            {info?.creditCard?.cardNumber?.slice(-4)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </PaymentDetails>
                    </div>
                  </SubscriptionDesc>
                </AccordionHeader>
              </>
            ),
            'aria-label': 'instant-ink-subscription'
          }}
          body={
            <Panel>
              {device?.productNumber ? (
                <SubscriptionIncludesPanel
                  t={t}
                  device={convertDevice(device, locale)}
                  printerModelName={subscription.printerModelName}
                />
              ) : (
                <span>{t('tryLater')}</span>
              )}
            </Panel>
          }
          expanded={true}
          id="instantInkSubscriptionAccordion"
          dataTestid="data-test-instantInkSubscription-accordion"
          subscription={subscription}
        />
      )}
    </Container>
  )
}
