import environments from '../../env.json'

const setEnvironmentVariables = (stack) => {
  const env = environments.find((e) => e.stack === stack)

  if (!env) return

  Object.keys(env.variables).forEach((key) => {
    process.env[key] = env.variables[key]
  })
}

export default setEnvironmentVariables
