import Images from '@/assets/images'

import { HPOneSubscriptionHeader } from '@/components/HPOneSubscriptionHeader/HPOneSubscriptionHeader'
import SubscriptionAccordion from '@/components/SubscriptionAccordion'
import { SubscriptionWrapper } from '@/components/SubscriptionWrapper/SubscriptionWrapper'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import React from 'react'
import { DelinquencyNotification } from '@jarvis/react-hpx-shared-components'
import {
  AccordionHeader,
  Container,
  DeviceIcon,
  Panel,
  PreArrivalPanel,
  DelinquencyNotificationStyle,
  NotificationSection
} from './styles'
import {
  allowedToShowNotificationStates,
  disabledStates,
  mblEntityType,
  notOnboardedStates
} from '@/types/Subscription'
import SubscriptionStateNotification from './../SubscriptionStateNotification/SubscriptionStateNotification'
import IconLaptop from '@veneer/core/dist/esm/scripts/icons/icon_laptop'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import useGetText from '@/hooks/useGetText'
import useDelinquencyNotification from '@/hooks/useDelinquencyNotification'
import { EDelinquencyNotification } from '@/types/pendingChanges'
import ErrorCard from '../ErrorCard'
import { ProgressContainer } from '../SubscriptionManagement/styles'
import Skeleton from '../Skeleton'
import { getMainEntity } from '@/helpers/uiConvert'
import { returnDate } from '@/utils/formatDaysToReturn'

export default function HPOneSubscription(props) {
  const getText = useGetText('SubscriptionManagement')
  const { subscription, subscriptionKey, ...parentProps } = props
  const {
    paymentMethodDetails,
    paymentMethodDetailsIsLoading,
    subscriptionDetailsIsLoading,
    billingDate,
    cancelledOn,
    returnDetails,
    customerEndDate
  } = useSubscriptionDetails()

  const mainEntity = getMainEntity(subscription)
  const subscriptionState = mainEntity?.state
  const userNotOnBoarded =
    (mainEntity?.entityDetails?.pendingAssociationState !== 'completed' ||
      !mainEntity?.entityDetails?.uniqueDeviceId ||
      notOnboardedStates[mainEntity?.entityDetails?.pendingAssociationState]) ??
    false
  const isDisabled = disabledStates[subscriptionState] ?? false
  const deviceMap = {
    [mblEntityType.pc]: {
      icon: <IconLaptop size={60} />,
      text: getText('notification.preArrival.pc')
    },
    [mblEntityType.chromebook]: {
      icon: <IconLaptop size={60} />,
      text: getText('notification.preArrival.pc')
    },
    [mblEntityType.printer]: {
      icon: <IconPrinter size={60} />,
      text: getText('notification.preArrival.printer')
    }
  }
  const expirationDate =
    paymentMethodDetails?.payment_method_details?.expiration_date
  const delinquencyStatus = useDelinquencyNotification(
    expirationDate,
    subscription
  )

  const subscriptionCentralArea = (
    <div className="header-area">
      <img id="hpOneIcon" src={Images.imgHpOne} alt="logo-hpOne"></img>
      {subscription?.friendlySubscriptionId && (
        <span>
          {getText('planId')} : {subscription.friendlySubscriptionId}
        </span>
      )}
    </div>
  )

  if (paymentMethodDetailsIsLoading || subscriptionDetailsIsLoading)
    return (
      <ProgressContainer>
        <Skeleton width="60vw" height="5vh" data-testid="skeleton" />
      </ProgressContainer>
    )

  const showSubscriptionStateNotification =
    allowedToShowNotificationStates[subscriptionState]
  const showDelinquencyNotification =
    delinquencyStatus && delinquencyStatus !== EDelinquencyNotification.NONE

  return (
    <Container>
      {mainEntity ? (
        <SubscriptionAccordion
          header={{
            centralArea: subscriptionCentralArea,
            previewArea: (
              <>
                <AccordionHeader>
                  <HPOneSubscriptionHeader {...parentProps} />
                </AccordionHeader>
                <NotificationSection>
                  {showSubscriptionStateNotification && (
                    <SubscriptionStateNotification
                      subscriptionState={subscriptionState}
                      mainEntity={mainEntity}
                      mblEntityType={mblEntityType}
                      subscription={subscription}
                      returnDetails={returnDetails}
                      billingDate={returnDate(
                        billingDate,
                        cancelledOn,
                        customerEndDate
                      )}
                      {...parentProps}
                    />
                  )}
                  {showDelinquencyNotification && (
                    <DelinquencyNotificationStyle className="delinquencySection">
                      <DelinquencyNotification
                        navigation={props.history}
                        notificationStatus={delinquencyStatus}
                      />
                    </DelinquencyNotificationStyle>
                  )}
                </NotificationSection>
              </>
            ),
            'aria-label': 'subscription',
            id: 'accordion-header'
          }}
          body={
            userNotOnBoarded && subscriptionState !== 'inactive' ? (
              <PreArrivalPanel isDisabled={isDisabled}>
                <DeviceIcon isDisabled={isDisabled}>
                  {deviceMap[mainEntity?.entityType]?.icon}
                </DeviceIcon>
                <div>{deviceMap[mainEntity?.entityType]?.text}</div>
              </PreArrivalPanel>
            ) : (
              <Panel>
                <SubscriptionWrapper isDisabled={isDisabled} />
              </Panel>
            )
          }
          id={`hpOneSubscriptionAccordion-${subscriptionKey}`}
          dataTestid="data-test-hpOneSubscription-accordion"
          subscription={subscription}
          expanded={true}
        />
      ) : (
        <ErrorCard getText={getText} />
      )}
    </Container>
  )
}
