import { CancellationInfo } from '@/types/subscription-response'

const getDateWithoutTimezone = (date: string | Date) => {
  date = new Date(date)
  const timezoneOffset = date.getTimezoneOffset()
  return new Date(date.getTime() - timezoneOffset * 60 * 1000)
}

export function getCurrentCancellationInfo(data: CancellationInfo) {
  if (!data?.subscription?.cancellation) {
    return data
  }
  const currentDate = getDateWithoutTimezone(new Date())

  const activeOptions = []

  for (const option of data.subscription.cancellation) {
    if (
      option.details &&
      option.details.validFrom &&
      option.details.validUntil
    ) {
      const validFrom = new Date(option.details.validFrom)
      const validUntil = new Date(option.details.validUntil)

      if (currentDate >= validFrom && currentDate <= validUntil) {
        activeOptions.push(option)
      }
    }
  }

  const updatedData = { ...data }
  updatedData.subscription.cancellation = activeOptions
  return updatedData
}
