import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { size4, xsMax } = tokens.layout
export const Panel = styled.div`
  box-sizing: border-box;
  width: 100%;
  & span > svg.iconLabel {
    margin-right: 5px;
    vertical-align: sub;
  }
`
export const AddonsContainer = styled.div`
  display: grid;
  gap: ${size4};
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media screen and (max-width: ${xsMax}) {
    grid-template-columns: auto;
  }
  & span > svg.iconLabel {
    margin-right: 5px;
    vertical-align: sub;
  }
`
