import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import useDataFetcher from '@/hooks/useDataFetcher'

const useHPOneSubscriptionsInfo = () => {
  const subscriptionClient = useSubscriptionClient()
  return useDataFetcher(
    'hpOneSubscriptionsInfo',
    subscriptionClient?.getAllSubscriptionsState
      ? subscriptionClient.getAllSubscriptionsState.bind(subscriptionClient)
      : null
  )
}

export default useHPOneSubscriptionsInfo
