import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { TranslatorFunctionType } from '@/types/localization'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import IconDocumentInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_document_ink_drop'
import IconInkDoubleDrops from '@veneer/core/dist/esm/scripts/icons/icon_ink_double_drops'
import { AddOn } from '../types/subscription'
import Images from '@/assets/images'
import {
  BoosterCardPanel,
  BoosterImgContainer,
  LabelValuePair,
  LabelDarkBlock,
  PrintPlanBlock
} from './styles'

type AddonProps = {
  t: TranslatorFunctionType
  isDisabled?: boolean
} & AddOn

const hardwares = {
  keyboard: { image: Images.imgKeyboard },
  mouse: { image: Images.imgMouse },
  pen: { image: Images.imgPen }
}

export function AddOnDevice(props: AddonProps) {
  const {
    t,
    name,
    image,
    friendlyName,
    type,
    pagePerMonth,
    entityStartDate,
    isDisabled
  } = props

  const hardwareAddon = (
    <StyleThemeProvider theme={useTheme()}>
      <BoosterCardPanel>
        {image ? (
          <BoosterImgContainer>
            <img src={image} alt="icon" className="boosterIcon" />
          </BoosterImgContainer>
        ) : (
          <BoosterImgContainer>
            <img
              src={hardwares[type].image}
              alt="icon"
              className="boosterIcon"
            />
          </BoosterImgContainer>
        )}

        <div>
          <LabelValuePair>
            {friendlyName ? (
              <span className="label">{`${friendlyName}`} </span>
            ) : (
              <span className="label">{name}</span>
            )}
            {friendlyName && <span className="caption-small">{name}</span>}
          </LabelValuePair>
        </div>
      </BoosterCardPanel>
    </StyleThemeProvider>
  )

  const printPlanAddon = (
    <StyleThemeProvider theme={useTheme()}>
      <BoosterCardPanel>
        <PrintPlanBlock isDisabled={isDisabled}>
          <IconInkDoubleDrops size={30} className="iconLabel" />
          <span className="body-large">
            {t('master-device-detail.print-plan', {
              defaultValue: 'Print plan'
            })}
          </span>
        </PrintPlanBlock>
        <div>
          <LabelDarkBlock isDisabled={isDisabled}>
            {pagePerMonth >= 0 && (
              <span className="label-small">
                <IconDocumentInkDrop size={14} className="iconLabel" />
                {pagePerMonth}{' '}
                {t('master-device-detail.pages-per-month', {
                  defaultValue: 'pages/month'
                })}
              </span>
            )}
            {entityStartDate && (
              <span className="label-small">
                <IconCalendarDay size={14} className="iconLabel" />
                {t('master-device-detail.service-enrollment-date', {
                  defaultValue: 'Service enrollment'
                })}{' '}
                {entityStartDate}
              </span>
            )}
          </LabelDarkBlock>
        </div>
      </BoosterCardPanel>
    </StyleThemeProvider>
  )
  return type === 'instant-ink' ? printPlanAddon : hardwareAddon
}
