import moment from 'moment'

export const formatDaysToReturn = (dateToFormat, daysToReturn) =>
  dateToFormat
    ? moment(dateToFormat).add(daysToReturn, 'days').format('MMM DD')
    : '.'

export const returnDate = (
  billingDate: string | null,
  cancelledOn: string | null,
  customerEndDate: string | null
) => {
  let result
  if (customerEndDate !== null) {
    result = customerEndDate
  } else if (billingDate !== null) {
    result = billingDate
  } else if (cancelledOn !== null) {
    result = cancelledOn
  } else {
    result = null
  }
  return result
}
