import { FulfillmentClient } from 'src/services/FulfillmentClient/fulfillmentClient'
import { SubscriptionEntity } from 'src/types/Subscription'

export const getIsDeviceShippedOrDelivered = async (
  fulfillmentClient: FulfillmentClient,
  entity: SubscriptionEntity
) => {
  const entityFulfillment =
    await fulfillmentClient.getFulfilmentOrderByFulfillmentOrderId(
      entity.fulfillment.value.fulfillmentOrderId
    )

  const foundPart = entityFulfillment
    .map((fulfillment) => fulfillment.parts)
    .flat()
    .find((part) => part.modelSku === entity.product.value.productSku)

  if (!foundPart) return false
  const statuses = foundPart.history?.map((old) => old.status) || []
  statuses.push(foundPart.status)
  return statuses.includes('shipped')
}
