import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { getCurrentCancellationInfo } from '@/utils/getCurrentCancellationInfo'
interface CancellationInfo {
  sId: string
  eId: string
  shouldFetch?: boolean
}
export const useCancelationInfo = ({
  sId,
  eId,
  shouldFetch = true
}: CancellationInfo) => {
  const client = useSubscriptionClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getCancelationInfo = async () => {
      setIsLoading(true)
      try {
        const response = await client.getCancellationInfo(sId, eId)
        setInfo(response)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from commerce api : ` +
            JSON.stringify(err?.message)
        )
        setError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (shouldFetch && sId && eId) {
      getCancelationInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sId, eId, shouldFetch])

  return {
    data: getCurrentCancellationInfo(info),
    error,
    isLoading
  }
}

export default useCancelationInfo
