import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import { useEffect, useState } from 'react'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useInstantInkPaymentInfo = () => {
  const client = useSubscriptionClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getPaymentInfo = async () => {
      setIsLoading(true)
      try {
        const response = await client.getBillingData()
        setInfo(response)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from api : ` + JSON.stringify(err?.message)
        )
        setError(true)
      } finally {
        setIsLoading(false)
      }
    }

    getPaymentInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    data: info,
    error,
    isLoading
  }
}

export default useInstantInkPaymentInfo
