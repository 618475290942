import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { size1, size0 } = tokens.typography
export const SubscriptionStateNotif = styled.div`
  margin: 0 -1.5rem;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: -0.15rem;
  > div {
    border-radius: 0;
    margin-bottom: -1rem;
    padding: 0 40px 0 40px !important;
  }
`
export const Title = styled.div`
  font-size: ${size1};
  font-weight: 700;
  p.description {
    font-weight: 400;
    font-size: ${size0};
  }
`

export const LinkWrapperElement = styled.div`
  display: flex;
  margin-top: ${size1};
`

export const LinkElement = styled.div`
  color: ${tokens.color.hpBlue6};
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${tokens.color.hpBlue6};
    outline: none;
  }
`
