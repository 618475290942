import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

interface AddOnDeviceProps {
  isDisabled?: boolean
}

const { cornerRadius2, size4, size3, size2 } = tokens.layout
const { highContrastGray, gray12, gray9 } = tokens.color
export const BoosterCardPanel = styled.div<AddOnDeviceProps>`
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorForegroundMedium};
  border-radius: ${cornerRadius2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${size4};
`
export const LabelValuePair = styled.div`
  margin-bottom: ${size3};
  &:last-child {
    margin-bottom: 0;
  }
  > span:nth-child(even) {
    color: ${({ theme }) => theme.colorForegroundLight};
  }

  > span {
    display: block;
  }
`
export const BoosterImgContainer = styled.div`
  align-self: center;
  .boosterIcon {
    margin-bottom: ${size2};
  }
  > img {
    margin-top: 0px;
    max-width: 300px;
    min-width: 100px;
    max-height: 140px;
  }
`
export const ImgHolder = styled.div`
  align-self: center;
  width: 70px;
`
export const LabelDarkBlock = styled.div`
  > span {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
    > svg {
      color: ${(props) => (props.isDisabled ? highContrastGray : gray12)};
    }
    display: block;
    margin-bottom: ${size3};
    &:last-child {
      margin-bottom: 0;
    }
  }
`
export const PrintPlanBlock = styled.div<AddOnDeviceProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
  > svg {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray12)};
  }
`
