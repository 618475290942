import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { PaymentMethods } from 'src/types/subscription-response'

type PaymentMethodResponse = {
  info: PaymentMethods
  error: boolean
  isLoading: boolean
}

export const usePaymentMethod = (
  paymentMethodId: string
): PaymentMethodResponse => {
  const client = useSubscriptionClient()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getPaymentMethodsInfo = async () => {
      setIsLoading(true)
      try {
        const data = await client.getPaymentMethods(paymentMethodId)
        setInfo(data)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from payment-methods api: ` +
            JSON.stringify(err)
        )
        setError(true)
      }
      setIsLoading(false)
    }

    if (paymentMethodId) {
      getPaymentMethodsInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodId])

  return {
    info,
    error,
    isLoading
  }
}
