import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import useDataFetcher from '@/hooks/useDataFetcher'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useInstantInkSubscriptionInfo = () => {
  const { loadInstantInkSubscriptions } = useFlags()
  const client = useSubscriptionClient()
  return useDataFetcher(
    'instantInkSubscriptionInfo',
    loadInstantInkSubscriptions && client?.getInstantInkSubscriptionData
      ? client.getInstantInkSubscriptionData.bind(client)
      : null
  )
}

export default useInstantInkSubscriptionInfo
