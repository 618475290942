import React from 'react'
import SubscriptionClient from '@/services/SubscriptionClient/SubscriptionClient'
import useShellContext from '@/hooks/useShellContext'

const useSubscriptionClient = () => {
  const { authProvider, stack } = useShellContext()
  const subClient = React.useRef<SubscriptionClient>(
    new SubscriptionClient(stack, authProvider)
  )

  return subClient.current
}

export default useSubscriptionClient
