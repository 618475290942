import { createContext } from 'react'
import AppState from '@/types/AppState'
import AppDispatch from '@/types/AppDispatch'

const AppContext = createContext<{
  state?: AppState
  dispatch?: AppDispatch
}>(undefined)

export default AppContext
