import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace
    .getTracer('subscription-management')
    .startSpan('subscription-management', {
      attributes: {
        'workflow.name': 'monetization/subscription-management.error',
        error: true,
        'error.message': message
      }
    })
  span.end()
}

export default sendOpenTelemetryEvent
