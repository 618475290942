import { AssetsProvider } from '@jarvis/web-assets-provider'
import { ActionsType } from './actions'

type ReturnSetAssetsProvider = {
  type: ActionsType.SET_ASSETS_PROVIDER
  data: AssetsProvider
}

export default function setAssetsProvider(
  assetsProvider: AssetsProvider
): ReturnSetAssetsProvider {
  return {
    type: ActionsType.SET_ASSETS_PROVIDER,
    data: assetsProvider
  }
}
