import { QueryResult, useQuery } from '@apollo/client'
import {
  GetProductsByParentSkuDocument,
  GetProductsByParentSkuQuery,
  GetProductsByParentSkuQueryVariables
} from '../../__generated__/graphql'

function useGetProductByParentSku(
  skus: string[]
): QueryResult<
  GetProductsByParentSkuQuery,
  GetProductsByParentSkuQueryVariables
> {
  return useQuery(GetProductsByParentSkuDocument, {
    skip: !skus.length,
    variables: {
      products: skus
    }
  })
}

export default useGetProductByParentSku
