import { useContext } from 'react'
import { ShellContext } from '@/context/shellContext'

const useShellContext = () => {
  const context = useContext(ShellContext)
  return {
    ...context,
    authProvider: context.authProvider
      ? context.authProvider
      : window?.Shell?.v1?.authProvider
  }
}
export default useShellContext
