import Images from '@/assets/images'
import { PaymentMethods } from '@/types/subscription-response'
import React from 'react'

export interface IPaymentInfo {
  children?: React.ReactNode
  paymentMethodDetails?: PaymentMethods
  friendlyName?: string
  plan_start_date?: string
}

export enum EPaymentType {
  PAYPAL = 'pay-pal',
  CREDITCARD = 'credit_card',
  CREDITCARDVISA = 'visa',
  CREDITCARDMASTER = 'mastercard',
  CREDITCARDMASTERCARD = 'master-card',
  CREDITCARDDISCOVER = 'discover',
  CREDITCARDAMEX = 'amex',
  CREDITCARDAMERICANX = 'american_express',
  AMERICAN_EXPRESS = 'american-express'
}

export const PaymentTypeIcon = {
  visa: Images.imgVisa,
  mastercard: Images.imgMastercard,
  amex: Images.imgAmex,
  discover: Images.imgDiscover,
  paypal: Images.imgPayPal,
  placeholder: Images.imgCardPlaceholder
}

export interface IPaymentInfoType {
  paymentMethodType?: string
  lastFour?: string
  lastFourEl?: string | JSX.Element
  logo: JSX.Element
}

export const logos = {
  [EPaymentType.CREDITCARDAMEX]: PaymentTypeIcon.amex,
  [EPaymentType.CREDITCARDAMERICANX]: PaymentTypeIcon.amex,
  [EPaymentType.AMERICAN_EXPRESS]: PaymentTypeIcon.amex,
  [EPaymentType.CREDITCARDVISA]: PaymentTypeIcon.visa,
  [EPaymentType.CREDITCARDMASTER]: PaymentTypeIcon.mastercard,
  [EPaymentType.CREDITCARDMASTERCARD]: PaymentTypeIcon.mastercard,
  [EPaymentType.CREDITCARDDISCOVER]: PaymentTypeIcon.discover
}
export const checkPaymentInfoType = (
  paymentMethodDetails
): IPaymentInfoType => {
  const paymentType = paymentMethodDetails?.payment_type
  const ccLogo = PaymentTypeIcon.placeholder

  if (paymentType === EPaymentType.CREDITCARD) {
    return {
      paymentMethodType: EPaymentType.CREDITCARD,
      logo: (
        <img
          src={
            logos[paymentMethodDetails?.card_type] ||
            PaymentTypeIcon.placeholder
          }
          alt={paymentMethodDetails?.card_type || 'placeholder'}
        />
      ),
      lastFourEl: paymentMethodDetails?.last_four ? (
        <span>
          &nbsp; XXXX-
          {paymentMethodDetails?.last_four}
        </span>
      ) : (
        ''
      ),
      lastFour: paymentMethodDetails?.last_four
    }
  } else {
    return {
      paymentMethodType: paymentType,
      logo: <img src={ccLogo} alt="placeholder" />
    }
  }
}
