import { useEffect, useState } from 'react'
import { EDelinquencyNotification } from '@/types/pendingChanges'
import { getExpirationStatus } from '@/helpers/getExpirationStatus'
import { SubscriptionStateResponse } from '@/types/subscription-state'
import useShellContext from '../useShellContext'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { PendingChangesClient } from '@/services/PendingChanges/PendingChangesClient'
import { SubscriptionStateEnum } from '@/types/Subscription'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useDelinquencyNotification = (
  expirationDate: string,
  subscription: SubscriptionStateResponse
) => {
  const notification_status =
    useDelinquencyWithHistoryNotification(subscription)

  if (subscription?.state === SubscriptionStateEnum.CANCELED) {
    return EDelinquencyNotification.NONE
  } else if (
    notification_status?.notificationStatus === EDelinquencyNotification.NONE
  ) {
    const expirationStatus = getExpirationStatus(expirationDate)
    return expirationStatus?.notificationStatus
  } else {
    return notification_status?.notificationStatus
  }
}

const fetchPendingChanges = async (
  printer: SubscriptionStateResponse,
  authProvider: JarvisAuthProvider,
  stack: Stack
) => {
  const pendingChangesClient = new PendingChangesClient(authProvider, stack)
  try {
    const { data } = await pendingChangesClient.getPendingChanges(
      printer?.subscriptionId
    )
    const hasPaymentIssueSuspended = data.contents.some(
      (item) => item.type === 'paymentIssue' && item.value.state === 'suspended'
    )

    return hasPaymentIssueSuspended
      ? EDelinquencyNotification.OVERDUE
      : EDelinquencyNotification.NONE
  } catch (e) {
    sendOpenTelemetryEvent(
      `Error while fetching data from pendingChanges api: ${JSON.stringify(e)}`
    )
    return EDelinquencyNotification.NONE
  }
}

export const useDelinquencyWithHistoryNotification = (
  hpOneSubscription: SubscriptionStateResponse
) => {
  const { stack, authProvider } = useShellContext()
  const [notificationStatus, setNotificationStatus] =
    useState<EDelinquencyNotification>()

  useEffect(() => {
    const fetchData = async () => {
      if (
        hpOneSubscription &&
        hpOneSubscription?.state === SubscriptionStateEnum.ACTIVE
      ) {
        const notificationStatus = await fetchPendingChanges(
          hpOneSubscription,
          authProvider,
          stack
        )
        setNotificationStatus(notificationStatus)
      } else if (
        hpOneSubscription &&
        hpOneSubscription?.state === SubscriptionStateEnum.SUSPENDED
      ) {
        setNotificationStatus(EDelinquencyNotification.SUSPENDED)
      } else {
        setNotificationStatus(EDelinquencyNotification.NONE)
      }
    }

    fetchData()
  }, [authProvider, hpOneSubscription, stack])

  return { notificationStatus }
}

export default useDelinquencyNotification
