export const formatStringDate = (rawDate: string, locale: string): string => {
  const JANUARY = 1
  const splittedDate = rawDate.split('-')
  const [year, month, day] = splittedDate.map((item) => parseInt(item))

  const dateAsDate = new Date(year, month - JANUARY, day)

  const formatter = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  })

  return formatter.format(dateAsDate)
}
