import HPOneSubscription from '@/components/HPOneSubscription'
import { withAppContext } from '@/context/AppProvider'
import { getSubscriptionKey, isInstantInk } from '@/helpers/uiConvert'
import useGetText from '@/hooks/useGetText'
import { publishEvent, SubscriptionScreenDisplayed } from '@/utils/analytics'
import '@veneer/core/dist/css/veneer.css'
import { useTheme } from '@veneer/theme'
import React, { useEffect } from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { Container, EmptyState, Heading, ProgressContainer } from './styles'

import Skeleton from '@/components/Skeleton'
import useSubscriptions from '@/hooks/useSubscriptions'
import ErrorCard from '../ErrorCard'
import InstantInkSubscription from '@/components/InstantInkSubscription'
import { SubscriptionDetailsProvider } from '@/context/SubscriptionDetailsContext/SubscriptionDetailsContext'
import { SubscriptionStateResponse } from 'src/types/subscription-state'

const SubscriptionManagement = (props) => {
  const getText = useGetText('SubscriptionManagement')
  const {
    emptyFlag,
    isFetching,
    hpOneError,
    instantInkError,
    deviceCaches,
    allSubscriptions,
    instantInkSubscription,
    skuProductMap,
    devicesGrant,
    devicesGrantError
  } = useSubscriptions()

  useEffect(() => {
    publishEvent(SubscriptionScreenDisplayed)
  }, [])

  return (
    <StyleThemeProvider theme={useTheme()}>
      <Container>
        <Heading role="heading" className="title-small">
          {getText('heading')}
        </Heading>
        {hpOneError || instantInkError || devicesGrantError ? (
          <ErrorCard getText={getText} />
        ) : (
          <>
            {emptyFlag && <EmptyState>{getText('emptyState')}</EmptyState>}
            {isFetching && (
              <ProgressContainer>
                <Skeleton width="60vw" height="5vh" />
              </ProgressContainer>
            )}
            {allSubscriptions?.map((subscription) => {
              const subscriptionKey = getSubscriptionKey(subscription)
              return isInstantInk(subscription) ? (
                <InstantInkSubscription
                  {...props}
                  data={instantInkSubscription?.find(
                    (ii) =>
                      ii.subscription?.accountIdentifier ===
                      subscription['accountIdentifier']
                  )}
                  t={getText}
                  key={subscriptionKey}
                />
              ) : (
                <SubscriptionDetailsProvider
                  subObj={subscription as SubscriptionStateResponse}
                  skuProductMap={skuProductMap}
                >
                  <HPOneSubscription
                    {...props}
                    skuProductMap={skuProductMap}
                    deviceCaches={deviceCaches}
                    subscription={subscription}
                    devicesGrant={devicesGrant}
                    key={subscriptionKey}
                    subscriptionKey={subscriptionKey}
                    forceRefresh={props.toggleIsMounted}
                  />
                </SubscriptionDetailsProvider>
              )
            })}
          </>
        )}
      </Container>
    </StyleThemeProvider>
  )
}

export default withAppContext(SubscriptionManagement)
