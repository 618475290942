import { ActionsType } from './actions'
import InfoKeys from '@/types/InfoKeys'
import InfoDatas from '@/types/InfoDatas'

type ReturnSetSucessFetch<K extends InfoKeys> = {
  type: ActionsType.SET_SUCCESS_FETCH
  data: {
    key: K
    data: InfoDatas[K]
  }
}

export default function setSucessFetch(
  key: InfoKeys,
  data: InfoDatas[InfoKeys]
): ReturnSetSucessFetch<InfoKeys> {
  return {
    type: ActionsType.SET_SUCCESS_FETCH,
    data: {
      key,
      data
    }
  }
}
