import React, { useEffect, useMemo, useRef, useState } from 'react'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'
import {
  mblEntityType,
  SubscriptionReturnStatusEnum,
  SubscriptionStateEnum,
  SubscriptionType
} from '@/types/Subscription'
import { getMainEntity } from '@/helpers/uiConvert'
import { getIsDeviceShippedOrDelivered } from '../../helpers/getIsDeviceShippedOrDelivered'
import { FulfillmentClient } from '../../services/FulfillmentClient/fulfillmentClient'
import 'regenerator-runtime/runtime'
import Skeleton from '../Skeleton'
import { StyledHeaderActionButton, TextDividerStyle } from './styles'

export function ManagementLinks(props) {
  const getText = useGetText('SubscriptionManagement')
  const { stack, properties: { portalLinkBehavior } = {} } = useShellContext()
  const {
    subscription,
    subscriptionDetailsIsLoading,
    returnDetails,
    returnDetailsLoading
  } = useSubscriptionDetails()
  const mainEntity = getMainEntity(subscription as SubscriptionType)
  const authProvider = props.authProvider || window?.Shell?.v1?.authProvider
  const fulfillmentOrdersAPIClient = useMemo(() => {
    return new FulfillmentClient(authProvider, stack)
  }, [authProvider, stack])
  const [isDeviceShippedOrDelivered, setIsDeviceShippedOrDelivered] =
    useState(false)

  const isComponentMountedRef = useRef(true)
  const managementHref =
    mainEntity?.entityType === mblEntityType.pc ||
    mainEntity?.entityType === mblEntityType.chromebook
      ? '/ucde/hp-all-in-plan'
      : '/ucde/hp-all-in-print-plan/overview'

  const cancelPlanHref = `/ucde/hp-all-in-print-plan/cancellation/${subscription.subscriptionId}`

  const showCancelLinkStates = [
    SubscriptionStateEnum.ACTIVE,
    SubscriptionStateEnum.PENDING,
    SubscriptionStateEnum.DEACTIVATING,
    SubscriptionStateEnum.SUSPENDED
  ]

  const isDisabled =
    mainEntity?.state === SubscriptionStateEnum.PENDING &&
    !isDeviceShippedOrDelivered

  useEffect(() => {
    const handleIsDeviceShippedOrDelivered = async () => {
      const isDeviceShippedOrDelivered = await getIsDeviceShippedOrDelivered(
        fulfillmentOrdersAPIClient,
        mainEntity
      )
      setIsDeviceShippedOrDelivered(isDeviceShippedOrDelivered)
    }
    if (isComponentMountedRef.current) {
      handleIsDeviceShippedOrDelivered()
    }
    return () => {
      isComponentMountedRef.current = false
    }
  }, [fulfillmentOrdersAPIClient, isDeviceShippedOrDelivered, mainEntity])

  const handleManageSubscription = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      window.location.href = managementHref
    else
      window.open(process.env.HPSMART_URL + managementHref, getWindowTarget())
  }
  const handleCancelPlan = (): void => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      props.history.push(`/${props.country}/${props.language}${cancelPlanHref}`)
    else
      window.open(process.env.HPSMART_URL + cancelPlanHref, getWindowTarget())
  }
  const getWindowTarget = (): string =>
    navigator.userAgent.includes('WebView') ? '_blank' : '_self'

  const checkReturnStatus = () => {
    const returnStatus =
      !returnDetailsLoading &&
      returnDetails?.parts?.find(
        (part) => part.modelSku === mainEntity?.product?.value?.productSku
      )?.status
    const hideCancelButtonStatuses = [
      SubscriptionReturnStatusEnum.TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL
    ]

    const hideCancelButton = hideCancelButtonStatuses.includes(
      returnStatus as SubscriptionReturnStatusEnum
    )

    if (
      mainEntity?.state === SubscriptionStateEnum.DEACTIVATING &&
      hideCancelButton
    ) {
      return false
    }
    return true
  }

  return (
    <div>
      {subscriptionDetailsIsLoading ? (
        <Skeleton width="160px" height="30px" />
      ) : (
        <>
          <span>
            <StyledHeaderActionButton
              onClick={handleManageSubscription}
              data-testid="manage-subscription-link-btn"
              tabindex="0"
              id="all-in-plan-manage-subscription-link-btn"
            >
              {getText('managePlan')}
            </StyledHeaderActionButton>
          </span>
          {showCancelLinkStates.includes(
            mainEntity?.state as SubscriptionStateEnum
          ) && (
            <>
              {checkReturnStatus() ? (
                <>
                  <TextDividerStyle> {`  |  `}</TextDividerStyle>
                  <span>
                    <StyledHeaderActionButton
                      onClick={handleCancelPlan}
                      data-testid="cancel-subscription-link-btn"
                      tabindex="0"
                      isDisabled={isDisabled}
                      id="all-in-plan-cancel-subscription-link-btn"
                    >
                      {mainEntity?.state === SubscriptionStateEnum.DEACTIVATING
                        ? getText('cancellationStatus')
                        : getText('cancelPlan')}
                    </StyledHeaderActionButton>
                  </span>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  )
}
