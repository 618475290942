import { useCallback } from 'react'
import useSettings from '../useSettings'
import useAssetsProvider from '../useAssetsProvider'

type Interpolation = {
  [key: string]: string
}

const useGetText = (prefixKey?: string) => {
  const { assetsProvider } = useSettings()
  useAssetsProvider()

  return useCallback(
    (subkey?: string, interpolation?: Interpolation) => {
      const key = [prefixKey, subkey].filter((e) => e).join('.')
      return assetsProvider.getText(key, interpolation)
    },
    [assetsProvider, prefixKey]
  )
}

export default useGetText
