import React, { useEffect } from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import {
  SubscriptionAccordionCollapsed,
  SubscriptionAccordionExpanded,
  publishEvent,
  ModuleDisplayed
} from '@/utils/analytics'

function SubscriptionAccordion({ header, body, ...extraProps }): JSX.Element {
  const { expanded, id, dataTestid, subscription } = extraProps
  const [data, setData] = React.useState([
    {
      expanded: expanded ?? false,
      header,
      content: body,
      disabled: !body
    }
  ])

  const handleExpand = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0

    setData((state) => {
      const updatedData = [...state]
      updatedData[index].expanded = true
      return updatedData
    })

    publishEvent(SubscriptionAccordionExpanded, { subscription })
  }

  const handleCollapse = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0
    else {
      setData((state) => {
        const updatedData = [...state]
        updatedData[index].expanded = false
        return updatedData
      })
    }
    publishEvent(SubscriptionAccordionCollapsed, { subscription })
  }

  useEffect(() => {
    publishEvent(ModuleDisplayed, { subscription })
  }, [])

  useEffect(() => {
    setData((state) => [{ ...state[0], header }])
  }, [header])

  return (
    <Accordion
      appearance={'dropShadow' as const}
      gutter={12}
      items={data}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
      id={id}
      data-testid={dataTestid}
    />
  )
}

export default SubscriptionAccordion
