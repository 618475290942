import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { size0, lineHeight7, lineHeight0 } = tokens.typography
const { cornerRadius2 } = tokens.layout
export const Container = styled.div`
  color: ${({ theme }) => theme.colorForegroundMedium};
  font-family: ${({ theme }) => theme.fontTextRegular};
  background-color: ${({ theme }) => theme.colorBackgroundContainer};
  width: 100%;
  .insinksubid {
    display: block;
    font-size: ${size0};
  }

  #instantInkSubscriptionAccordion {
    margin-bottom: ${lineHeight7};
    > div {
      border-radius: ${cornerRadius2};
      > div:first-child {
        padding: 1.5rem 1.5rem 1rem 1rem;
      }

      > div:nth-child(2) {
        padding: 0 0.01rem 0.5rem;
      }
      [aria-label^='Chevron Down'] {
        fill: #0278ab;
        margin-bottom: 21px;
      }
      [aria-hidden^='false'] {
        margin: 0px;
        padding: 1rem 1rem;
      }
    }
  }
`
export const Panel = styled.div`
  display: flex;
  flex-direction: column;
`
export const AccordionHeader = styled.div`
  font-family: ${({ theme }) => theme.fontTextRegular};
  color: ${({ theme }) => theme.colorForegroundMedium};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: ${lineHeight0};
`
export const SubscriptionDesc = styled.div`
  diplay: flex;
  flex-flow: column;
  width: 100%;
  > span {
    display: block;
  }
  .links-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`
export const SubscriptionTotal = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  align-self: flex-end;
`
export const EditLink = styled.div`
  margin-bottom: 7px;
`
export const PaymentDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .last-4-digits {
    color: ${tokens.color.gray9};
    text-align: right;
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.lineHeight1};
  }
`
