import React, { createContext, useContext } from 'react'
import {
  DeviceCache,
  Device,
  AddOn,
  SubscriptionType,
  RetrieveReturnResponse
} from '@/types/Subscription'
import { useFetchSubscriptionDetails } from '@/hooks/useFetchSubscriptionDetails'
import { ProductInterface } from 'src/__generated__/graphql'
import { useCommerceInfo } from '@/hooks/useCommerceInfo/useCommerceInfo'
import { useRetrieveReturns } from '@/hooks/useRetrieveReturns/useRetrieveReturns'
import { usePaymentMethod } from '@/hooks/usePaymentMethod/usePaymentMethod'
import { PaymentMethods } from '@/types/subscription-response'
import { SubscriptionStateResponse } from '@/types/subscription-state'

export const SubscriptionDetailsContext =
  createContext<HPOneSubscriptionDetailData>(undefined)

interface SubscriptionDetailProviderProps {
  subObj: SubscriptionStateResponse
  skuProductMap: Map<string, ProductInterface>
  deviceCaches?: DeviceCache[]
}

export interface HPOneSubscriptionDetailData {
  subscription: SubscriptionStateResponse | SubscriptionType
  device?: Device
  addOns?: AddOn[]
  billingDate?: string
  cancelledOn?: string
  billingDateLoading?: boolean
  paymentMethodDetails?: PaymentMethods
  paymentMethodDetailsIsLoading: boolean
  subscriptionDetailsIsLoading: boolean
  returnDetails?: RetrieveReturnResponse
  returnDetailsLoading: boolean
  customerEndDate?: string
}

export const SubscriptionDetailsProvider: React.FC<
  SubscriptionDetailProviderProps
> = ({ subObj, skuProductMap, deviceCaches, children }) => {
  const {
    subObj: subscription,
    addOns,
    device,
    isLoading: subscriptionDetailsIsLoading
  } = useFetchSubscriptionDetails({
    subObj,
    skuProductMap,
    deviceCaches
  })
  const { data: commerceInfo, isLoading: commerceInfoLoading } =
    useCommerceInfo({ subObj })

  const {
    info: paymentMethodDetails,
    isLoading: paymentMethodDetailsIsLoading
  } = usePaymentMethod(subObj.paymentMethodId)

  const { data: returnDetails, isLoading: returnDetailsLoading } =
    useRetrieveReturns(subObj.tenantId)

  const contextValue = React.useMemo(
    () => ({
      subscription,
      addOns,
      device,
      billingDate: commerceInfo?.billingDate,
      customerEndDate: commerceInfo?.customerEndDate,
      billingDateLoading: commerceInfoLoading,
      cancelledOn: commerceInfo?.cancelledOn,
      paymentMethodDetails: paymentMethodDetails,
      paymentMethodDetailsIsLoading,
      subscriptionDetailsIsLoading,
      returnDetails,
      returnDetailsLoading
    }),
    [
      subscription,
      addOns,
      device,
      commerceInfo,
      commerceInfoLoading,
      paymentMethodDetails,
      paymentMethodDetailsIsLoading,
      subscriptionDetailsIsLoading,
      returnDetails,
      returnDetailsLoading
    ]
  )

  return (
    <SubscriptionDetailsContext.Provider value={contextValue}>
      {children}
    </SubscriptionDetailsContext.Provider>
  )
}

export function useSubscriptionDetails() {
  const context = useContext(SubscriptionDetailsContext)
  if (context === undefined) {
    throw new Error(
      'useSubscriptionDetails must be used within a SubscriptionDetailsProvider'
    )
  }
  return context
}
