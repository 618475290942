import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'
import React from 'react'
import {
  LinksContainer,
  StyledHeaderActionButton,
  TextDividerStyle
} from './styles'
import { InstantInkSubscription } from '@/types/subscription-response'

export function InstantInkManagementLinks(props) {
  const { subscription, country, history, language } = props
  const getText = useGetText('SubscriptionManagement')
  const { properties: { portalLinkBehavior } = {} } = useShellContext()
  const accid = `?acc_id=${subscription.accountIdentifier}`
  const printPlansHref = `/ucde/print_plans${accid}`
  const cancelPlanHref = `/ucde/print_plans/cancellation${accid}`

  const handleManageSubscription = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      history.push(`/${country}/${language}${printPlansHref}`)
    else window.open(process.env.HPSMART_URL + printPlansHref, '_blank')
  }

  const handleCancelSubscription = () => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      history.push(`/${country}/${language}${cancelPlanHref}`)
    else window.open(process.env.HPSMART_URL + cancelPlanHref, '_blank')
  }

  const disabledStates = ['initiated_unsubscribe', 'unsubscribed', 'obsolete']

  const isDisabled = (subscription: InstantInkSubscription): boolean => {
    return disabledStates.includes(subscription.state)
  }

  return (
    <LinksContainer>
      <span>
        <StyledHeaderActionButton
          className="label-small"
          onClick={handleManageSubscription}
          data-testid="instant-ink-manage-subscription-link"
          tabindex="0"
          id="instant-ink-manage-subscription-link-btn"
        >
          {getText('manageSubscription')}
        </StyledHeaderActionButton>
      </span>
      <TextDividerStyle className="label-small"> {` | `}</TextDividerStyle>
      <span>
        <StyledHeaderActionButton
          isDisabled={isDisabled(subscription)}
          className="label-small"
          onClick={handleCancelSubscription}
          data-testid="instant-ink-cancel-subscription-link"
          tabindex="0"
          id="instant-ink-cancel-subscription-link-btn"
        >
          {getText('cancelSubscription')}
        </StyledHeaderActionButton>
      </span>
    </LinksContainer>
  )
}
