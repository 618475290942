import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

export const useRetrieveReturns = (tenantId: string) => {
  const client = useSubscriptionClient()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState(undefined)

  useEffect(() => {
    const getRetrieveReturns = async () => {
      setIsLoading(true)
      try {
        const data = await client.retrieveReturns(tenantId)
        setData(data)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from retrieve returns api : ` +
            JSON.stringify(err)
        )
        setError(true)
      }
      setIsLoading(false)
    }

    if (tenantId) {
      getRetrieveReturns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])

  return {
    data,
    error,
    isLoading
  }
}
