import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import IconLaptop from '@veneer/core/dist/esm/scripts/icons/icon_laptop'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import { Device } from '../types/subscription'
import { TranslatorFunctionType } from '@/types/localization'
import Images from '@/assets/images'
import {
  DeviceImage,
  Panel,
  DeviceInfo,
  ProductNo,
  SerialNo,
  EligibleFor
} from './styles'
import Skeleton from '@/components/Skeleton'
const hardwares = {
  'iot-printer': {
    icon: <IconPrinter size={30} />,
    image: Images.imgPrinter,
    refreshType: 'master-device-detail.printer'
  },
  'iot-pc': {
    icon: <IconLaptop size={30} />,
    image: Images.imgLaptop,
    refreshType: 'master-device-detail.pc'
  },
  chromebook: {
    icon: <IconLaptop size={30} />,
    image: Images.imgLaptop,
    refreshType: 'master-device-detail.pc'
  }
}

type DeviceProps = {
  t: TranslatorFunctionType
  belowTitleArea?: React.ReactNode
  isDisabled?: boolean
  printerModelName?: string
} & Device

export function MasterDeviceDetail(props: DeviceProps) {
  const {
    t,
    name,
    friendlyName,
    image,
    productNumber,
    serialNumber,
    eligibleForRefresh,
    belowTitleArea,
    type,
    isDisabled,
    printerModelName
  } = props
  const formattedProductNumber = productNumber?.split('#')[0]

  return (
    <StyleThemeProvider theme={useTheme()}>
      <Panel>
        {!productNumber ? (
          <Skeleton width="60vw" height="5vh" />
        ) : (
          <>
            <DeviceInfo isDisabled={isDisabled}>
              <div>
                {hardwares[type].icon}
                <h4 className="title-small">{printerModelName || name} </h4>
                {belowTitleArea}
              </div>
              <div>
                {productNumber && (
                  <>
                    <ProductNo isDisabled={isDisabled} className="label-small">
                      {t('master-device-detail.product-number', {
                        defaultValue: 'Product number'
                      })}
                    </ProductNo>
                    <span className="caption-small">
                      {formattedProductNumber}
                    </span>
                  </>
                )}
                {serialNumber && (
                  <>
                    <SerialNo isDisabled={isDisabled} className="label-small">
                      {t('master-device-detail.serial-number', {
                        defaultValue: 'Serial number'
                      })}
                    </SerialNo>
                    <span className="caption-small">{serialNumber}</span>
                  </>
                )}
                {eligibleForRefresh && (
                  <>
                    <EligibleFor
                      isDisabled={isDisabled}
                      className="label-small"
                    >
                      {t('master-device-detail.eligible-for-refresh', {
                        refreshType: `${t(`${hardwares[type].refreshType}`, {
                          defaultValue: 'printer'
                        })}`,
                        defaultValue: 'Eligible for {{refreshType}} upgrade'
                      })}
                    </EligibleFor>
                    <span className="caption-small">
                      <IconCalendarDay size={14} className="iconLabel" />
                      {eligibleForRefresh}
                    </span>
                  </>
                )}
              </div>
            </DeviceInfo>
            <DeviceImage isDisabled={isDisabled}>
              <img
                alt={friendlyName ? friendlyName : name}
                src={image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = Images.printerPlaceholder
                }}
              />
            </DeviceImage>
          </>
        )}
      </Panel>
    </StyleThemeProvider>
  )
}
